/// <reference types="./content_library.d.mts" />
import * as $birl from "../../birl/birl.mjs";
import * as $connector from "../../common/data/connector.mjs";
import * as $data_source from "../../common/data/data_source.mjs";
import { DataSource, StatusStats } from "../../common/data/data_source.mjs";
import * as $tag from "../../common/data/tag.mjs";
import * as $bool from "../../gleam_stdlib/gleam/bool.mjs";
import * as $dict from "../../gleam_stdlib/gleam/dict.mjs";
import * as $list from "../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../gleam_stdlib/gleam/option.mjs";
import * as $result from "../../gleam_stdlib/gleam/result.mjs";
import * as $msg from "../data/msg.mjs";
import * as $spreadsheet_selector from "../data/ui/spreadsheet_selector.mjs";
import { toList, prepend as listPrepend, CustomType as $CustomType, isEqual } from "../gleam.mjs";
import * as $utils from "../utils.mjs";

export class NewQna extends $CustomType {
  constructor(question, answer, loading, existing_document) {
    super();
    this.question = question;
    this.answer = answer;
    this.loading = loading;
    this.existing_document = existing_document;
  }
}

export class Sources extends $CustomType {
  constructor(all, all_size, verified, verified_size, pending, pending_size, not_verified, not_verified_size, outdated, outdated_size) {
    super();
    this.all = all;
    this.all_size = all_size;
    this.verified = verified;
    this.verified_size = verified_size;
    this.pending = pending;
    this.pending_size = pending_size;
    this.not_verified = not_verified;
    this.not_verified_size = not_verified_size;
    this.outdated = outdated;
    this.outdated_size = outdated_size;
  }
}

export class ContentLibrary extends $CustomType {
  constructor(non_qna_sources, non_qna_status, qna_sources, qna_status, selected, change_owner_popup_opened, change_status_popup_opened, change_tags_popup_opened, change_expiration_date_popup_opened, change_expiration_date_input, filter_selected, search, new_qna, ingestion_spreadsheet, ingestion_loading) {
    super();
    this.non_qna_sources = non_qna_sources;
    this.non_qna_status = non_qna_status;
    this.qna_sources = qna_sources;
    this.qna_status = qna_status;
    this.selected = selected;
    this.change_owner_popup_opened = change_owner_popup_opened;
    this.change_status_popup_opened = change_status_popup_opened;
    this.change_tags_popup_opened = change_tags_popup_opened;
    this.change_expiration_date_popup_opened = change_expiration_date_popup_opened;
    this.change_expiration_date_input = change_expiration_date_input;
    this.filter_selected = filter_selected;
    this.search = search;
    this.new_qna = new_qna;
    this.ingestion_spreadsheet = ingestion_spreadsheet;
    this.ingestion_loading = ingestion_loading;
  }
}

export function from_iso_string_to_display_string(iso_string) {
  if (iso_string === "fr") {
    return "French";
  } else if (iso_string === "en") {
    return "English";
  } else if (iso_string === "es") {
    return "Spanish";
  } else if (iso_string === "de") {
    return "German";
  } else if (iso_string === "nl") {
    return "Dutch";
  } else if (iso_string === "pt") {
    return "Portuguese";
  } else if (iso_string === "it") {
    return "Italian";
  } else {
    return iso_string;
  }
}

export function init_new_qna() {
  return new NewQna("", "", false, new $option.None());
}

export function set_filter(content_library, filter) {
  return content_library.withFields({ filter_selected: filter });
}

function do_upsert_data_source(sources, source) {
  if (sources.atLeastLength(1) && (sources.head.id === source.id)) {
    let s = sources.head;
    let rest = sources.tail;
    return listPrepend(source, rest);
  } else if (sources.atLeastLength(1)) {
    let s = sources.head;
    let rest = sources.tail;
    return listPrepend(s, do_upsert_data_source(rest, source));
  } else {
    return toList([source]);
  }
}

function compute_sources(all) {
  let verified = $list.filter(
    all,
    (s) => { return isEqual(s.status, new $data_source.Verified()); },
  );
  let pending = $list.filter(
    all,
    (s) => { return isEqual(s.status, new $data_source.Pending()); },
  );
  let not_verified = $list.filter(
    all,
    (s) => { return isEqual(s.status, new $data_source.NotVerified()); },
  );
  let outdated = $list.filter(
    all,
    (s) => { return isEqual(s.status, new $data_source.Outdated()); },
  );
  return new Sources(
    all,
    $list.length(all),
    verified,
    $list.length(verified),
    pending,
    $list.length(pending),
    not_verified,
    $list.length(not_verified),
    outdated,
    $list.length(outdated),
  );
}

function update_sources(sources, mapper) {
  let all = $list.map(sources.all, mapper);
  return compute_sources(all);
}

export function upsert_data_source(content_library, source) {
  let $ = source.kind;
  if ($ instanceof $connector.SteerlabQna) {
    let _pipe = do_upsert_data_source(content_library.qna_sources.all, source);
    let _pipe$1 = compute_sources(_pipe);
    return ((qna_sources) => {
      return content_library.withFields({ qna_sources: qna_sources });
    })(_pipe$1);
  } else {
    let _pipe = do_upsert_data_source(
      content_library.non_qna_sources.all,
      source,
    );
    let _pipe$1 = compute_sources(_pipe);
    return ((non_qna_sources) => {
      return content_library.withFields({ non_qna_sources: non_qna_sources });
    })(_pipe$1);
  }
}

export function set_qna_sources(content_library, sources) {
  let qna_sources = compute_sources(sources);
  return content_library.withFields({ qna_sources: qna_sources });
}

export function set_non_qna_sources(content_library, sources) {
  let non_qna_sources = compute_sources(sources);
  return content_library.withFields({ non_qna_sources: non_qna_sources });
}

export function set_data_sources(content_library, sources) {
  let qna_sources = $list.filter(
    sources,
    (s) => { return isEqual(s.kind, new $connector.SteerlabQna()); },
  );
  let sources$1 = $list.filter(
    sources,
    (s) => { return !isEqual(s.kind, new $connector.SteerlabQna()); },
  );
  let _pipe = content_library;
  let _pipe$1 = set_qna_sources(_pipe, qna_sources);
  return set_non_qna_sources(_pipe$1, sources$1);
}

function update_tags(s, tag, add_remove) {
  let tags = (() => {
    if (add_remove instanceof $msg.Remove) {
      return $list.filter(s.tags, (t) => { return t !== tag.id; });
    } else {
      let _pipe = $list.reverse(s.tags);
      let _pipe$1 = $list.prepend(_pipe, tag.id);
      let _pipe$2 = $list.reverse(_pipe$1);
      return $list.unique(_pipe$2);
    }
  })();
  return s.withFields({ tags: tags });
}

export function select(content_library, id) {
  let selected = $dict.insert(content_library.selected, id, true);
  return content_library.withFields({ selected: selected });
}

export function deselect(content_library, id) {
  let selected = $dict.delete$(content_library.selected, id);
  return content_library.withFields({ selected: selected });
}

export function deselect_all(content_library) {
  let selected = $dict.new$();
  return content_library.withFields({ selected: selected });
}

export function is_selected(content_library, id) {
  let _pipe = $dict.get(content_library.selected, id);
  return $result.unwrap(_pipe, false);
}

function update_selected_sources(content_library, mapper) {
  return update_sources(
    content_library.non_qna_sources,
    (source) => {
      let is_selected$1 = is_selected(content_library, source.id);
      return $bool.guard(
        !is_selected$1,
        source,
        () => { return mapper(source); },
      );
    },
  );
}

function update_selected_qna_sources(content_library, mapper) {
  return update_sources(
    content_library.qna_sources,
    (source) => {
      let is_selected$1 = is_selected(content_library, source.id);
      return $bool.guard(
        !is_selected$1,
        source,
        () => { return mapper(source); },
      );
    },
  );
}

export function attribute_source(content_library, user_id) {
  let owner = new $option.Some(user_id);
  let update_owner = (s) => { return s.withFields({ owner: owner }); };
  let non_qna_sources = update_selected_sources(content_library, update_owner);
  let qna_sources = update_selected_qna_sources(content_library, update_owner);
  return content_library.withFields({
    non_qna_sources: non_qna_sources,
    qna_sources: qna_sources,
    selected: $dict.new$()
  });
}

export function attribute_status(content_library, status) {
  let update_status = (s) => { return s.withFields({ status: status }); };
  let non_qna_sources = update_selected_sources(content_library, update_status);
  let qna_sources = update_selected_qna_sources(content_library, update_status);
  return content_library.withFields({
    non_qna_sources: non_qna_sources,
    selected: $dict.new$(),
    qna_sources: qna_sources
  });
}

export function attribute_tag(content_library, tag, add_remove) {
  let update_tags$1 = (_capture) => {
    return update_tags(_capture, tag, add_remove);
  };
  let non_qna_sources = update_selected_sources(content_library, update_tags$1);
  let qna_sources = update_selected_qna_sources(content_library, update_tags$1);
  return content_library.withFields({
    non_qna_sources: non_qna_sources,
    qna_sources: qna_sources
  });
}

export function attribute_expiration_date(content_library, expiration_date) {
  let expiration_date$1 = new $option.Some(expiration_date);
  let update_expiration_date = (s) => {
    return s.withFields({ expiration_date: expiration_date$1 });
  };
  let non_qna_sources = update_selected_sources(
    content_library,
    update_expiration_date,
  );
  let qna_sources = update_selected_qna_sources(
    content_library,
    update_expiration_date,
  );
  return content_library.withFields({
    non_qna_sources: non_qna_sources,
    qna_sources: qna_sources,
    selected: $dict.new$()
  });
}

function new_status() {
  return new StatusStats(0, 0, 0, 0);
}

export function new$() {
  let now = $birl.utc_now();
  return new ContentLibrary(
    compute_sources(toList([])),
    new_status(),
    compute_sources(toList([])),
    new_status(),
    $dict.new$(),
    false,
    false,
    false,
    false,
    $utils.time_to_qualified_day(now, "-"),
    new $option.None(),
    "",
    init_new_qna(),
    $spreadsheet_selector.new$(),
    false,
  );
}

export function toggle_change_owner(content_library) {
  return content_library.withFields({
    change_owner_popup_opened: !content_library.change_owner_popup_opened,
    change_status_popup_opened: false,
    change_tags_popup_opened: false,
    change_expiration_date_popup_opened: false
  });
}

export function toggle_change_expiration_date(content_library) {
  return content_library.withFields({
    change_owner_popup_opened: false,
    change_status_popup_opened: false,
    change_tags_popup_opened: false,
    change_expiration_date_popup_opened: !content_library.change_expiration_date_popup_opened,
    change_expiration_date_input: $utils.time_to_qualified_day(
      $birl.utc_now(),
      "-",
    )
  });
}

export function toggle_change_status(content_library) {
  return content_library.withFields({
    change_status_popup_opened: !content_library.change_status_popup_opened,
    change_owner_popup_opened: false,
    change_tags_popup_opened: false,
    change_expiration_date_popup_opened: false
  });
}

export function toggle_change_tags(content_library) {
  return content_library.withFields({
    change_tags_popup_opened: !content_library.change_tags_popup_opened,
    change_owner_popup_opened: false,
    change_status_popup_opened: false,
    change_expiration_date_popup_opened: false
  });
}

export function close_popups(content_library) {
  return content_library.withFields({
    change_tags_popup_opened: false,
    change_owner_popup_opened: false,
    change_status_popup_opened: false,
    change_expiration_date_popup_opened: false
  });
}

export function reset_ingestion_spreadsheet(content_library) {
  let ingestion_spreadsheet = $spreadsheet_selector.new$();
  let ingestion_loading = false;
  return content_library.withFields({
    ingestion_spreadsheet: ingestion_spreadsheet,
    ingestion_loading: ingestion_loading
  });
}

export function set_new_qna(content_library, new_qna) {
  return content_library.withFields({ new_qna: new_qna });
}

export function filtered_non_qna_sources(content_library) {
  let sources = content_library.non_qna_sources;
  let $ = content_library.filter_selected;
  if ($ instanceof $option.Some && $[0] instanceof $data_source.Verified) {
    return sources.verified;
  } else if ($ instanceof $option.Some && $[0] instanceof $data_source.Pending) {
    return sources.pending;
  } else if ($ instanceof $option.Some &&
  $[0] instanceof $data_source.NotVerified) {
    return sources.not_verified;
  } else if ($ instanceof $option.Some && $[0] instanceof $data_source.Outdated) {
    return sources.outdated;
  } else {
    return sources.all;
  }
}

export function filtered_qna_sources(content_library) {
  let sources = content_library.qna_sources;
  let $ = content_library.filter_selected;
  if ($ instanceof $option.Some && $[0] instanceof $data_source.Verified) {
    return sources.verified;
  } else if ($ instanceof $option.Some && $[0] instanceof $data_source.Pending) {
    return sources.pending;
  } else if ($ instanceof $option.Some &&
  $[0] instanceof $data_source.NotVerified) {
    return sources.not_verified;
  } else if ($ instanceof $option.Some && $[0] instanceof $data_source.Outdated) {
    return sources.outdated;
  } else {
    return sources.all;
  }
}

export function filtered_non_qna_size(content_library) {
  let sources = content_library.non_qna_sources;
  let $ = content_library.filter_selected;
  if ($ instanceof $option.Some && $[0] instanceof $data_source.Verified) {
    return sources.verified_size;
  } else if ($ instanceof $option.Some && $[0] instanceof $data_source.Pending) {
    return sources.pending_size;
  } else if ($ instanceof $option.Some &&
  $[0] instanceof $data_source.NotVerified) {
    return sources.not_verified_size;
  } else if ($ instanceof $option.Some && $[0] instanceof $data_source.Outdated) {
    return sources.outdated_size;
  } else {
    return sources.all_size;
  }
}

export function all_non_qna_selected(content_library) {
  return $dict.size(content_library.selected) === filtered_non_qna_size(
    content_library,
  );
}

export function filtered_qna_size(content_library) {
  let sources = content_library.qna_sources;
  let $ = content_library.filter_selected;
  if ($ instanceof $option.Some && $[0] instanceof $data_source.Verified) {
    return sources.verified_size;
  } else if ($ instanceof $option.Some && $[0] instanceof $data_source.Pending) {
    return sources.pending_size;
  } else if ($ instanceof $option.Some &&
  $[0] instanceof $data_source.NotVerified) {
    return sources.not_verified_size;
  } else if ($ instanceof $option.Some && $[0] instanceof $data_source.Outdated) {
    return sources.outdated_size;
  } else {
    return sources.all_size;
  }
}

export function all_qna_selected(content_library) {
  return $dict.size(content_library.selected) === filtered_qna_size(
    content_library,
  );
}

export function set_non_qna_status(content_library, non_qna_status) {
  return content_library.withFields({ non_qna_status: non_qna_status });
}
