import { jwtDecode } from 'jwt-decode'
import * as uuid_ from 'uuid'
import * as xlsx from 'xlsx'
import * as gleam from './gleam.mjs'

export function uuid() {
  return uuid_.v4()
}

export function updateTitle(title) {
  document.title = title
  return null
}

export function blurActiveElement() {
  document.activeElement?.blur()
}

export function getActiveElementNearestId() {
  let node = document.activeElement
  while (node && !node.id) node = node.parentElement
  if (node?.id) return new gleam.Ok(node.id)
  return new gleam.Error()
}

export function formatDate(date) {
  const asDate = new Date(date)
  return new Intl.DateTimeFormat().format(asDate)
}

export function log(data) {
  console.log(data)
  return data
}

export function getDataFromEvent(event) {
  if (event.data) return event.data
  return null
}

let id = 0

export function uniqueId() {
  return id++
}

export function fromCharcode(charcode) {
  return String.fromCharCode(charcode)
}

export function toggleDomFreeze() {
  const elements = document.getElementsByTagName('*')
  if (document.body.style.getPropertyValue('overflow')) {
    document.body.style.removeProperty('overflow')
    for (const element of elements) {
      if (element.style.getPropertyValue('overflow')) element.style.removeProperty('overflow')
    }
  } else {
    document.body.style.setProperty('overflow', 'hidden')
    for (const element of elements) {
      const styles = window.getComputedStyle(element)
      if (styles.overflow === 'auto') element.style.setProperty('overflow', 'hidden')
    }
  }
}

export function subscribeDOMClick(dispatch) {
  const run = () => {
    try {
      dispatch()
    } catch (e) {
      console.log(e)
    }
  }
  setTimeout(() => {
    document.addEventListener('click', run, { once: true })
  }, 100)
  return () => document.removeEventListener('click', run)
}

export function coerce(a) {
  return a
}

export function blurTargetWithEscape(event) {
  if (event.key === 'Escape') {
    blurTarget(event)
  }
}

export function blurTarget(event) {
  event.preventDefault()
  event.target?.blur()
}

export function extractInnerText(event) {
  return new gleam.Ok(event.target.innerText)
}

export async function dlFile(name, body) {
  return new Promise(resolve => {
    const blob = new Blob([body.buffer])
    const url = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', `${name}.xlsx`)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    resolve()
  })
}

export function readPermissions(token) {
  try {
    const decoded = jwtDecode(token)
    return new gleam.Ok(decoded.permissions)
  } catch (error) {
    return new gleam.Error()
  }
}

export function subscribeFeedScroll() {
  const id = document.getElementById('feed-wrapper')
  if (!id) {
    setTimeout(() => subscribeFeedScroll(), 100)
  } else {
    id.addEventListener('scroll', event => {
      const scroll = event.target.scrollTop
      const node = document.getElementById('feed-title')
      if (!node) return
      if (scroll > 0 && !node.style.borderBottomColor) {
        node.setAttribute('style', 'border-bottom-color: #DADAE2;')
      } else {
        node.removeAttribute('style')
      }
    })
  }
}

export function scrollToQuestion(questionId, iteration = 0) {
  if (iteration === 10) return
  const id = `question-${questionId}`
  const node = document.getElementById(id)
  const mainLayout = document.getElementById('layout-main')
  if (!node || !mainLayout) {
    setTimeout(() => scrollToQuestion(questionId, iteration + 1), 300)
  } else {
    const elemRect = node.getBoundingClientRect()
    const offsetTop = elemRect.top + mainLayout.scrollTop
    const offsetLeft = elemRect.left - mainLayout.scrollLeft
    mainLayout.scrollTo({ top: offsetTop, left: offsetLeft, behavior: 'smooth' })
  }
}

export async function allSettled(promises) {
  const results = await Promise.allSettled(promises)
  return gleam.List.fromArray(results)
}

export function readFileEvent(event) {
  if (!event.target.files) return new gleam.Error()
  const files = [...event.target.files]
  return new gleam.Ok(gleam.List.fromArray(files))
}

export function getEventBoundingClientRect(event) {
  const containsClass = el => el.getAttribute('class')?.includes?.('dropdown-parent-wrapper') ?? false
  let target = event.target
  while (target && !containsClass(target)) target = target.parentElement
  if (!target) return new gleam.Error('No event target')
  if (!target.getBoundingClientRect) return new gleam.Error('No bounding rect function')
  const rect = target.getBoundingClientRect()
  if (!rect) return new gleam.Error('No bounding rect')
  return new gleam.Ok(rect)
}
