/// <reference types="./layout.d.mts" />
import * as $data_source from "../../common/data/data_source.mjs";
import * as $proposal from "../../common/data/proposal.mjs";
import * as $question from "../../common/data/question.mjs";
import * as $list from "../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../gleam_stdlib/gleam/option.mjs";
import { None, Some } from "../../gleam_stdlib/gleam/option.mjs";
import * as $result from "../../gleam_stdlib/gleam/result.mjs";
import * as $a from "../../lustre/lustre/attribute.mjs";
import * as $el from "../../lustre/lustre/element.mjs";
import * as $h from "../../lustre/lustre/element/html.mjs";
import * as $e from "../../lustre/lustre/event.mjs";
import * as $model from "../data/model.mjs";
import * as $msg from "../data/msg.mjs";
import * as $route from "../data/route.mjs";
import * as $button from "../design_system/components/button.mjs";
import * as $layout from "../design_system/components/layout.mjs";
import * as $modal from "../design_system/components/modal.mjs";
import * as $skeleton from "../design_system/components/skeleton.mjs";
import { toList } from "../gleam.mjs";
import * as $utils from "../utils.mjs";
import * as $content_library from "../view/content_library.mjs";
import * as $copilot from "../view/copilot.mjs";
import * as $feed from "../view/feed.mjs";
import * as $navbar from "../view/navbar.mjs";
import * as $organization from "../view/organization.mjs";
import * as $overview from "../view/overview.mjs";
import * as $proposals from "../view/proposals.mjs";
import * as $settings from "../view/settings.mjs";
import * as $s from "../view/styles.mjs";

function select_page_title(route) {
  if (route instanceof $route.Overview) {
    return new $option.Some("Overview");
  } else if (route instanceof $route.CoPilot) {
    return new $option.Some("Co-pilot");
  } else if (route instanceof $route.ContentLibrary) {
    return new $option.Some("Content Library");
  } else if (route instanceof $route.Proposals && route[0] instanceof $route.Add) {
    return new $option.Some("New Proposal");
  } else if (route instanceof $route.Proposals &&
  route[0] instanceof $route.OnGoing) {
    return new $option.Some("Proposals");
  } else if (route instanceof $route.Proposals &&
  route[0] instanceof $route.Previous) {
    return new $option.Some("Proposals");
  } else if (route instanceof $route.Settings) {
    return new $option.Some("Settings");
  } else if (route instanceof $route.Organization) {
    return new $option.Some("Organization");
  } else if (route instanceof $route.Support) {
    return new $option.Some("Support");
  } else if (route instanceof $route.NotFound) {
    return new $option.Some("Not Found");
  } else {
    return new $option.None();
  }
}

function select_catchphrase(model) {
  let user = $model.find_current_user(model);
  let $ = model.route;
  if ($ instanceof $route.Overview) {
    return new $option.Some(
      [
        $layout.row(
          toList([$layout.align("center"), $layout.gap(16)]),
          toList([]),
          toList([
            $el.text("Hello "),
            $skeleton.hide_loading(
              user,
              200,
              40,
              (user) => { return $el.text(user.name); },
            ),
          ]),
        ),
        $el.text("Your overview and requests at a glance."),
      ],
    );
  } else if ($ instanceof $route.NotFound) {
    return new Some([$el.text("Not Found"), $el.text("Not Found")]);
  } else {
    return new None();
  }
}

function view_delete_proposal(proposal) {
  let title = ("Are you sure you want to delete " + proposal.name) + "?";
  let explanations = "Deleting the proposal can not be reversed.";
  return $modal.modal(
    toList([]),
    toList([
      $modal.body(
        toList([$utils.stop_propagation()]),
        toList([
          $modal.title(toList([]), toList([$h.text(title)])),
          $modal.explanations(toList([]), toList([$h.text(explanations)])),
          $modal.actions(
            toList([]),
            toList([
              $button.primary(
                toList([$e.on_click(new $msg.UserClosedModal())]),
                toList([$h.text("Cancel")]),
              ),
              $button.secondary(
                toList([$e.on_click(new $msg.UserDeletedProposal(proposal.id))]),
                toList([$h.text("Submit")]),
              ),
            ]),
          ),
        ]),
      ),
    ]),
  );
}

function view_delete_question_in_proposal(question) {
  let title = "Are you sure you want the remove the question ?";
  let explanations = "This line will still be present in the exported file just without an AI answer.";
  return $modal.modal(
    toList([]),
    toList([
      $modal.body(
        toList([$utils.stop_propagation()]),
        toList([
          $modal.title(toList([]), toList([$h.text(title)])),
          $modal.explanations(toList([]), toList([$h.text(explanations)])),
          $modal.actions(
            toList([]),
            toList([
              $button.primary(
                toList([$e.on_click(new $msg.UserClosedModal())]),
                toList([$h.text("Cancel")]),
              ),
              $button.secondary(
                toList([
                  $e.on_click(
                    new $msg.UserDeletedQuestionInProposal(
                      question.proposal_id,
                      question.id,
                    ),
                  ),
                ]),
                toList([$h.text("Submit")]),
              ),
            ]),
          ),
        ]),
      ),
    ]),
  );
}

function view_delete_data_source(data_source) {
  let title = ("Are you sure you want to delete " + data_source.display.name) + "?";
  let explanations = "Deleting this content can not be reversed.";
  return $modal.modal(
    toList([]),
    toList([
      $modal.body(
        toList([$utils.stop_propagation()]),
        toList([
          $modal.title(toList([]), toList([$h.text(title)])),
          $modal.explanations(toList([]), toList([$h.text(explanations)])),
          $modal.actions(
            toList([]),
            toList([
              $button.primary(
                toList([$e.on_click(new $msg.UserClosedModal())]),
                toList([$h.text("Cancel")]),
              ),
              $button.secondary(
                toList([
                  $e.on_click(new $msg.UserDeletedDataSource(data_source.id)),
                ]),
                toList([$h.text("Submit")]),
              ),
            ]),
          ),
        ]),
      ),
    ]),
  );
}

function view_modal(model) {
  let $ = model.display_modal;
  if ($ instanceof $model.NoModal) {
    return $el.none();
  } else if ($ instanceof $model.DeleteProposal) {
    let id = $.id;
    let _pipe = $list.key_find(model.proposals, id);
    let _pipe$1 = $result.map(_pipe, view_delete_proposal);
    return $result.unwrap(_pipe$1, $el.none());
  } else if ($ instanceof $model.DeleteDataSource) {
    let id = $.id;
    let _pipe = model.content_library.non_qna_sources.all;
    let _pipe$1 = $list.find(_pipe, (s) => { return s.id === id; });
    let _pipe$2 = $result.map(_pipe$1, view_delete_data_source);
    return $result.unwrap(_pipe$2, $el.none());
  } else {
    let proposal_id = $.proposal_id;
    let question_id = $.question_id;
    let _pipe = $model.find_question(model, proposal_id, question_id);
    let _pipe$1 = $result.map(_pipe, view_delete_question_in_proposal);
    return $result.unwrap(_pipe$1, $el.none());
  }
}

export function view(model) {
  let page_title = select_page_title(model.route);
  let catchphrase = select_catchphrase(model);
  return $el.fragment(
    toList([
      $s.layout(
        toList([]),
        toList([
          $navbar.navbar(model),
          $feed.feed(model),
          $s.main(
            toList([$a.id("layout-main")]),
            toList([
              (() => {
                if (page_title instanceof $option.None &&
                catchphrase instanceof $option.None) {
                  return $el.none();
                } else if (page_title instanceof $option.None &&
                catchphrase instanceof $option.Some) {
                  let title = catchphrase[0][0];
                  let subtitle = catchphrase[0][1];
                  return $s.page_header(
                    toList([]),
                    toList([
                      $s.page_catchphrase(
                        toList([]),
                        toList([
                          $s.catch_title(toList([]), toList([title])),
                          $s.catch_subtitle(toList([]), toList([subtitle])),
                        ]),
                      ),
                    ]),
                  );
                } else if (page_title instanceof $option.Some &&
                catchphrase instanceof $option.None) {
                  let t = page_title[0];
                  return $s.page_header(
                    toList([]),
                    toList([$s.page_title(toList([]), toList([$h.text(t)]))]),
                  );
                } else {
                  let t = page_title[0];
                  let title = catchphrase[0][0];
                  let subtitle = catchphrase[0][1];
                  return $s.page_header(
                    toList([]),
                    toList([
                      $s.page_title(toList([]), toList([$h.text(t)])),
                      $s.page_catchphrase(
                        toList([]),
                        toList([
                          $s.catch_title(toList([]), toList([title])),
                          $s.catch_subtitle(toList([]), toList([subtitle])),
                        ]),
                      ),
                    ]),
                  );
                }
              })(),
              (() => {
                let $ = model.route;
                if ($ instanceof $route.Overview) {
                  return $overview.view(model);
                } else if ($ instanceof $route.Organization) {
                  return $organization.view(model);
                } else if ($ instanceof $route.ContentLibrary) {
                  return $content_library.view(model);
                } else if ($ instanceof $route.Proposals) {
                  return $proposals.view(model);
                } else if ($ instanceof $route.CoPilot) {
                  return $copilot.view(model);
                } else if ($ instanceof $route.Settings) {
                  return $settings.view(model);
                } else {
                  return $el.none();
                }
              })(),
            ]),
          ),
        ]),
      ),
      view_modal(model),
    ]),
  );
}
