import auth0/client as auth0
import auth0/client/options
import data/content_library
import data/data_source
import data/model.{type Model, Model}
import data/msg.{type Msg}
import data/notification.{type Notification, Notification}
import data/proposal
import data/question.{type Question, Question}
import data/route
import data/ui/loading
import data/user
import design_system/components/dropdown
import frontend/effects
import frontend/effects/content_library as content_library_effects
import frontend/effects/copilot as copilot_effects
import frontend/effects/organization as organization_effects
import frontend/effects/proposal as proposal_effects
import frontend/effects/question as question_effects
import frontend/effects/user as user_effects
import frontend/ffi
import frontend/middleware.{require_not_modifying_question}
import frontend/update
import frontend/websocket
import frontend/websocket/messages
import gleam/bool
import gleam/dict
import gleam/dynamic
import gleam/function
import gleam/io
import gleam/list
import gleam/option
import gleam/pair
import gleam/result
import gleam/string
import grille_pain
import grille_pain/lustre/toast
import lustre
import lustre/effect
import modem
import plinth/browser/window
import plinth/javascript/global
import sentry
import sketch
import sketch/magic
import update/connectors
import update/content_library.{update as update_content_library} as _
import update/new_proposal.{update as update_new_proposal} as _
import view/layout
import view/login
import view/slack_login
import vitools

fn init_sentry() {
  vitools.get_env("SENTRY_DSN")
  |> result.map_error(fn(_) { io.print_error("No VITE_SENTRY_DSN found") })
  |> result.map(sentry.init)
}

pub fn main() {
  let stylesheet = magic.document()
  let assert Ok(_) = grille_pain.simple()
  let assert Ok(_) = ffi.lustre_portal_setup()
  let assert Ok(cache) = sketch.cache(strategy: sketch.Ephemeral)
  let assert Ok(_) = dropdown.setup(stylesheet)
  let assert Ok(_) = init_sentry()
  let assert Ok(_) = magic.setup(cache)
  let assert Ok(dispatch) =
    view(_, stylesheet)
    |> lustre.application(init, update, _)
    |> lustre.start("#app", Nil)
  let assert Ok(_) = websocket.setup(dispatch)
}

fn create_client(params) {
  let assert Ok(client_id) = vitools.get_env("AUTH0_CLIENT_ID")
  let assert Ok(domain) = vitools.get_env("AUTH0_DOMAIN")
  options.new(domain, client_id)
  |> options.use_refresh_token(True)
  |> options.use_refresh_token_fallback(False)
  |> options.with_authorization_params(params)
  |> auth0.create
}

fn auth_client() {
  options.authorization_params()
  |> options.audience("https://api.steerlab.ai/")
  |> options.scope("openid profile email")
  |> create_client()
}

fn invite_client(organization organization, invitation invitation) {
  options.authorization_params()
  |> options.audience("https://api.steerlab.ai/")
  |> options.scope("openid profile email")
  |> options.organization(organization)
  |> options.invitation(invitation)
  |> create_client()
}

fn init(_flags) {
  let assert Ok(route) = modem.initial_uri() |> result.map(route.from_uri)
  let modem_init =
    modem.init(fn(uri) { msg.OnRouteChange(route.from_uri(uri)) })
  let update_title = route.update_title(route)
  let client = auth_client()
  let eff = auth0.init(client, msg.AuthStateChanged)
  let model = model.new(client, route)
  let initial_effect = select_route_effect(model)
  effect.batch([
    eff,
    modem_init,
    update_title,
    initial_effect,
    listen_popup_messages(),
    effect.from(fn(_) { ffi.subscribe_feed_scroll() }),
  ])
  |> pair.new(model, _)
}

fn listen_popup_messages() {
  use dispatch <- effect.from()
  window.add_event_listener("message", fn(event) {
    let data = ffi.get_data_from_event(event)
    case dynamic.string(data) {
      Ok("flow-ended") -> dispatch(msg.FetchUserData)
      _ -> Nil
    }
  })
}

fn select_route_effect(model: Model) {
  case model.route {
    route.SlackLogin(..) -> user_effects.register_slack_user(model)
    route.Proposals(route.Show(proposal_id, ..)) -> {
      use dispatch <- effect.from()
      dispatch(msg.ApplicationQueriedProposalQuestions(0, proposal_id))
    }
    route.Login(invitation:, organization:) -> {
      case invitation, organization {
        option.Some(invitation), option.Some(organization) -> {
          let client = invite_client(organization:, invitation:)
          auth0.login_with_redirect(client, msg.AuthStateChanged)
        }
        _, _ -> effect.none()
      }
    }
    _ -> effect.none()
  }
}

fn update(model: Model, msg: Msg) {
  case msg {
    msg.Back -> #(model, modem.back(1))

    msg.ApplicationPerformedRequest(id) -> {
      let running_requests = [id, ..model.running_requests]
      let model = model.Model(..model, running_requests:)
      #(model, effect.none())
    }

    msg.ApplicationCompletedRequest(id) -> {
      let is_id = fn(i) { i != id }
      let running_requests = list.filter(model.running_requests, is_id)
      let model = model.Model(..model, running_requests:)
      #(model, effect.none())
    }

    msg.UserClickedProposal(id, sheet) ->
      case model.route {
        route.Proposals(route.Show(pid, ..)) if pid == id -> route.replace
        _ -> route.push
      }
      |> fn(new_entry) { new_entry(route.Proposals(route.Show(id, sheet, 0))) }
      |> pair.new(model, _)

    msg.UserDeletedProposal(id) -> {
      let found = list.key_find(model.proposals, id) |> result.is_ok
      use <- bool.guard(when: !found, return: #(model, effect.none()))
      let proposals =
        model.proposals
        |> list.filter(fn(proposal) { { proposal.1 }.id != id })
      model.Model(..model, proposals:, display_modal: model.NoModal)
      |> pair.new(proposal_effects.delete_proposal(model, id))
    }

    msg.UserDeletedQuestionInProposal(proposal_id, question_id) -> {
      let found =
        model.find_question(model, proposal_id, question_id) |> result.is_ok
      use <- bool.guard(when: !found, return: #(model, effect.none()))
      let updated_model = model.remove_question(model, proposal_id, question_id)
      model.Model(..updated_model, display_modal: model.NoModal)
      |> model.empty_popup("ai-" <> question_id)
      |> model.recompute_displayed_questions(proposal_id)
      |> pair.new(proposal_effects.delete_question_in_proposal(
        model,
        proposal_id,
        question_id,
      ))
      |> pair.map_second(fn(e) {
        effect.batch([model.unsubscribe_more_proposal(model), e])
      })
    }

    msg.UserDeletedDataSource(id) -> {
      let by_id = fn(s: data_source.DataSource) { s.id == id }
      case model.content_library.non_qna_sources.all |> list.find(by_id) {
        Error(_) -> #(model, effect.none())
        Ok(data_source) -> {
          model.content_library.non_qna_status
          |> data_source.decrease_status_stats(data_source)
          |> content_library.set_non_qna_status(model.content_library, _)
          |> content_library.set_non_qna_sources({
            model.content_library.non_qna_sources.all
            |> list.filter(fn(s) { s.id != id })
          })
          |> model.set_content_library(model, _)
          |> pair.new(content_library_effects.delete_data_source(model, id))
        }
      }
    }

    msg.UserDisplayedDeleteProposalModal(id) -> {
      model.Model(..model, display_modal: model.DeleteProposal(id))
      |> pair.new({
        effects.subscribe_dom_click(fn(_) { msg.None }, msg.UserClosedModal)
      })
    }

    msg.UserDisplayedDeleteDataSourceModal(id) -> {
      model.Model(..model, display_modal: model.DeleteDataSource(id))
      |> pair.new({
        effects.subscribe_dom_click(fn(_) { msg.None }, msg.UserClosedModal)
      })
    }

    msg.UserDisplayedDeleteQuestionInProposalModal(proposal_id, question_id) -> {
      model.Model(
        ..model,
        display_modal: model.DeleteQuestionInProposal(proposal_id, question_id),
      )
      |> pair.new({
        effects.subscribe_dom_click(fn(_) { msg.None }, msg.UserClosedModal)
      })
    }
    msg.UserClosedModal -> {
      let new_model = model.Model(..model, display_modal: model.NoModal)
      #(new_model, effect.none())
    }

    msg.ApiReturnedProposalsQuestions(
      proposal_id:,
      questions:,
      data_sources:,
      data_points:,
    ) -> {
      use <- require_not_modifying_question(model)
      let filters =
        proposal.ProposalFilters(status: proposal.AllStatus, owner: option.None)
      model
      |> model.mark_as_loaded(loading.questions_loaded)
      |> model.add_proposal_questions(questions)
      |> model.set_proposal_filters(filters)
      |> model.close_filter_proposal
      |> model.recompute_displayed_questions(proposal_id)
      |> model.add_questions_data_sources(data_sources)
      |> model.add_questions_data_points(data_points)
      |> pair.new(effect.none())
    }

    msg.ApiTimeoutedProposalsQuestions -> {
      model
      |> model.mark_as_loaded(loading.questions_loaded)
      |> pair.new(effect.none())
    }

    msg.OnCopilotThreads(#(questions, data_sources, data_points)) -> {
      use <- require_not_modifying_question(model)
      model
      |> model.add_copilot_questions(questions)
      |> model.add_questions_data_sources(data_sources)
      |> model.add_questions_data_points(data_points)
      |> pair.new(effect.none())
    }

    msg.ApplicationQueriedProposalQuestions(timeout, proposal_id) -> {
      case model.route {
        route.Proposals(route.Show(pid, ..)) if pid == proposal_id ->
          model
          |> model.mark_as_loaded(loading.questions_loading)
          |> pair.new({
            question_effects.query_proposal_questions(model, timeout, pid)
          })
        _ -> #(model, effect.none())
      }
    }

    msg.OnRouteChange(route) -> {
      let model = model.update_route(model, route)
      let eff = select_route_effect(model)
      model
      |> model.empty_new_proposal
      |> model.empty_popup("all")
      |> pair.new(effect.batch([route.update_title(route), eff]))
    }

    msg.AuthStateChanged(user) -> {
      case user {
        option.None -> #(model.new(model.client, model.route), effect.none())
        option.Some(#(access_token, user)) -> {
          model
          |> model.update_access_token(access_token)
          |> model.update_connected_user(user)
          |> fn(model) { #(model, effects.on_connected_user(model)) }
        }
      }
    }

    msg.DocumentReturnedMoreButtonUnsubscriber(unsubscriber) -> {
      let more_proposal_unsubscriber = option.Some(unsubscriber)
      Model(..model, more_proposal_unsubscriber:)
      |> pair.new(effect.none())
    }

    msg.UserToggledQuestionMoreButton(question_id:) -> {
      case model.more_proposal_opened {
        option.Some(qid) if qid == question_id -> #(
          model.Model(..model, more_proposal_opened: option.None),
          model.unsubscribe_more_proposal(model),
        )
        option.Some(_) -> #(
          model.Model(..model, more_proposal_opened: option.Some(question_id)),
          model.unsubscribe_more_proposal(model),
        )
        option.None -> #(
          model.Model(..model, more_proposal_opened: option.Some(question_id)),
          msg.DocumentReturnedMoreButtonUnsubscriber
            |> effects.subscribe_dom_click(msg.ClosePopups("ai-" <> question_id)),
        )
      }
    }

    msg.ApiRejectedProposal(proposal) -> {
      model.upsert_proposals(model, [proposal])
      |> pair.new({
        [
          "Your proposal modification has not been handled.",
          "Please, retry later.",
        ]
        |> string.join(" ")
        |> toast.error
      })
    }

    msg.ApiReturnedProposals(proposals) -> {
      use <- require_not_modifying_question(model)
      model.upsert_proposals(model, proposals)
      |> pair.new(effect.none())
    }

    msg.Auth0(auth0) ->
      case auth0 {
        auth0.Authorize(_) -> #(model, effect.none())
        auth0.Authorized(_) -> #(model, effect.none())
        auth0.Login -> {
          let handler = case model.route {
            route.SlackLogin(..) -> auth0.login_with_popup
            _ -> auth0.login_with_redirect
          }
          model.client
          |> handler(msg.AuthStateChanged)
          |> pair.new(model, _)
        }
        auth0.Logout ->
          model.client
          |> auth0.logout(msg.AuthStateChanged)
          |> pair.new(model, _)
      }

    msg.OnConnectors(msg) -> {
      use <- require_not_modifying_question(model)
      connectors.handle_on_connectors(model, msg)
    }

    msg.FetchUserData -> #(model, effects.fetch_user_data(model))

    msg.OnNewProposal(msg) -> update_new_proposal(model, msg)

    msg.OnCopilotInput(content) ->
      model
      |> model.update_copilot_input(content)
      |> pair.new(effect.none())

    msg.UserEdittedCustomRewording(content) ->
      model
      |> model.update_custom_rewording_input(content)
      |> pair.new(effect.none())

    msg.ContentLibrary(msg) -> update_content_library(model, msg)

    msg.UserUpdatedProposalQuestion(question:, content:) -> {
      let optimistic_question = question.update_custom_answer(question, content)
      model
      |> question_effects.prepare_updating_question(question, content)
      |> model.upsert_proposal_question(optimistic_question)
      |> pair.new(question_effects.update_question(model, question, content))
    }

    msg.UserUpdatedProposalQuestionYesNo(question:, yes_no:) ->
      model
      |> question_effects.prepare_updating_question_yes_no(question, yes_no)
      |> pair.new({
        question_effects.update_question_yes_no(model, question, yes_no)
      })

    msg.UserUpdatedProposalQuestionChoice(question:, choice:) ->
      model
      |> question_effects.prepare_updating_question_choice(question, choice)
      |> pair.new({
        question_effects.update_question_choice(model, question, choice)
      })

    msg.ApiReturnedProposalQuestion(question:) ->
      model
      |> model.upsert_proposal_question(question)
      |> pair.new(effect.none())

    msg.ApiRejectedProposalQuestion(question:) ->
      model
      |> model.upsert_proposal_question(question)
      |> pair.new(toast.error("Unable to update question. Please, retry later."))

    msg.OnCopilotQuestionUpdated(question:) ->
      model
      |> model.add_copilot_questions([question])
      |> pair.new(effect.none())

    msg.OpenLink(link: option.None) -> #(model, effect.none())
    msg.OpenLink(link: option.Some(#(filename, link))) -> {
      #(model, content_library_effects.open_link(model, filename, link))
    }

    msg.UserUpdatedProposalTitle(id, name) -> {
      case list.key_find(model.proposals, id) {
        Error(_) -> #(model, effect.none())
        Ok(proposal) -> {
          proposal.Proposal(..proposal, name:)
          |> list.key_set(model.proposals, id, _)
          |> fn(proposals) { model.Model(..model, proposals:) }
          |> pair.new(effect.none())
        }
      }
    }

    msg.SaveProposalTitle(id) -> {
      [
        effects.blur_active_element(),
        proposal_effects.update_proposal_name(model, id),
      ]
      |> effect.batch
      |> pair.new(model, _)
    }

    msg.DisplayToast(level:, message:) -> #(model, {
      toast.options()
      |> toast.level(level)
      |> toast.custom(message)
    })

    msg.HideToast(id) -> #(model, toast.hide(id))

    msg.Xlsx(xlsx) -> handle_xlsx(model, xlsx)

    msg.OnCopilotSubmit -> {
      let thread_id = ffi.uuid()
      route.push(route.CoPilot(route.AiResponse(thread_id)))
      |> list.prepend([copilot_effects.submit_copilot(model, thread_id)], _)
      |> effect.batch
      |> pair.new(model.reset_copilot_input(model), _)
    }

    msg.OnUsers(users) -> {
      use <- require_not_modifying_question(model)
      model.Model(..model, users:)
      |> model.mark_as_loaded(loading.users)
      |> pair.new(effect.none())
    }

    msg.ToggleProposalCollaboratorsPopup(question_id) -> {
      let qid = option.Some(question_id)
      let is_question = model.collaborators_proposal_opened == qid
      let collaborators_proposal_opened = case is_question {
        True -> option.None
        False -> qid
      }
      model.Model(..model, collaborators_proposal_opened:)
      |> pair.new(case collaborators_proposal_opened {
        option.None -> effect.none()
        option.Some(_) ->
          effects.subscribe_dom_click(
            fn(_) { msg.None },
            msg.ClosePopups("collaborator-" <> question_id),
          )
      })
    }

    msg.AddQuestionOwner(question_id:, proposal_id:, user_id:) -> {
      let uid = option.Some(user_id)
      case model.find_question(model, proposal_id:, question_id:) {
        Error(_) -> #(model, effect.none())
        Ok(Question(owner:, ..)) if owner == uid -> #(model, effect.none())
        Ok(question) -> {
          let question = Question(..question, owner: uid, validated: False)
          let upsert = msg.UpsertQuestion(question)
          use e <- pair.map_second(update(model, upsert))
          let eff =
            question_effects.update_question_owner(model:, question:, user_id:)
          effect.batch([e, eff])
        }
      }
    }

    msg.OnUserFunctionSelected(user_id:, function:) -> {
      let users = {
        let function = option.Some(function)
        use u <- list.map(model.users)
        use <- bool.guard(when: u.id != user_id, return: u)
        user.User(..u, function:)
      }
      model.Model(..model, users:)
      |> pair.new(organization_effects.update_user_function(
        model,
        user_id,
        function,
      ))
    }

    msg.UserClickedAiRewrite(proposal_id:, question_id:, asked:) -> {
      let question = model.find_question(model, proposal_id, question_id)
      case question {
        Error(_) -> #(model, effect.none())
        Ok(question) -> {
          let question = question.Question(..question, ai_processing: True)
          model
          |> model.empty_popup("ai-" <> question_id)
          |> update(msg.UpsertQuestion(question))
          |> pair.map_second(fn(e) {
            [
              model.unsubscribe_more_proposal(model),
              question_effects.ask_ai_rewording(
                model:,
                proposal_id:,
                question_id:,
                asked:,
                custom: "",
              ),
            ]
            |> list.prepend(e)
            |> effect.batch()
          })
        }
      }
    }
    msg.UserClickedAddToQuestionBank(proposal_id:, question_id:) -> {
      let question = model.find_question(model, proposal_id, question_id)
      case question {
        Error(_) -> #(model, effect.none())
        Ok(question) -> {
          let answer =
            question.answer.custom
            |> option.or(question.answer.long)
            |> option.unwrap("")
          let question = question.content
          let new_qna =
            content_library.NewQna(
              question:,
              answer:,
              existing_document: option.None,
              loading: False,
            )
          content_library.ContentLibrary(..model.content_library, new_qna:)
          |> model.set_content_library(model, _)
          |> update_content_library(msg.UserSubmittedQuestionBankEdit)
          |> pair.map_second(fn(e) {
            effect.batch([model.unsubscribe_more_proposal(model), e])
          })
        }
      }
    }

    msg.UserToggledProposalCollaborator(proposal_id: pid, user_id:) -> {
      model.proposals
      |> list.key_find(pid)
      |> result.map(proposal.toggle_collaborator(_, user_id))
      |> result.map(list.wrap)
      |> result.map(model.upsert_proposals(model, _))
      |> result.unwrap(model)
      |> pair.new({
        proposal_effects.toggle_proposal_collaborator(model, pid, user_id)
      })
    }

    msg.UserSubmittedCustomRewording(proposal_id:, question_id:) -> {
      let custom_rewording_input = model.custom_rewording_input
      use <- bool.guard(when: custom_rewording_input == "", return: #(
        model,
        effect.none(),
      ))
      let question = model.find_question(model, proposal_id, question_id)
      case question {
        Error(_) -> #(model, effect.none())
        Ok(question) -> {
          let question = question.Question(..question, ai_processing: True)
          model
          |> model.empty_popup("ai-" <> question_id)
          |> model.reset_custom_rewording_input
          |> update(msg.UpsertQuestion(question))
          |> pair.map_second(fn(e) {
            [
              model.unsubscribe_more_proposal(model),
              question_effects.ask_ai_rewording(
                model:,
                proposal_id:,
                question_id:,
                asked: "custom",
                custom: custom_rewording_input,
              ),
            ]
            |> list.prepend(e)
            |> effect.batch()
          })
        }
      }
    }
    msg.UserUpdatedProposalFiltersStatus(proposal_id, status) -> {
      proposal.ProposalFilters(..model.proposal_filters, status:)
      |> model.set_proposal_filters(model, _)
      |> model.recompute_displayed_questions(proposal_id)
      |> pair.new(effect.none())
    }

    msg.UserUpdatedProposalFiltersOwner(proposal_id, owner) -> {
      proposal.ProposalFilters(..model.proposal_filters, owner:)
      |> model.set_proposal_filters(model, _)
      |> model.recompute_displayed_questions(proposal_id)
      |> pair.new(effect.none())
    }

    msg.ToggleFeed -> {
      let feed_opened = !model.feed_opened
      #(model.Model(..model, feed_opened:), case feed_opened {
        True ->
          effects.subscribe_dom_click(
            fn(_) { msg.None },
            msg.ClosePopups("feed"),
          )
        False -> effect.none()
      })
    }
    msg.UserToggledFilterProposal(proposal_id) -> {
      proposal.ProposalFilters(status: proposal.AllStatus, owner: option.None)
      |> model.set_proposal_filters(model, _)
      |> model.recompute_displayed_questions(proposal_id)
      |> model.toggle_filter_proposal_opened()
      |> pair.new(case model.filter_proposal_opened {
        False -> effect.none()
        True ->
          msg.ClosePopups("proposal-filter")
          |> effects.subscribe_dom_click(fn(_) { msg.None }, _)
      })
    }
    msg.UpdateNotifications(notifications:) -> {
      use <- require_not_modifying_question(model)
      model
      |> model.set_notifications(notifications)
      |> pair.new(effect.none())
    }

    msg.UpdateNotification(notification:) ->
      model
      |> model.set_notification(notification)
      |> pair.new(effect.none())

    msg.MarkAllNotificationsAsRead ->
      model.notifications
      |> list.map(fn(n) { Notification(..n, read: True) })
      |> model.set_notifications(model, _)
      |> pair.new(user_effects.mark_all_notifications_as_read(model))

    msg.UserClickedNotification(notification:) -> {
      let notification_id = notification.id
      let effs = case notification.content {
        notification.ProposalAssigned(proposal_id:) ->
          route.push(route.Proposals(route.Show(proposal_id, option.None, 0)))
        notification.Question(proposal_id:, question_id:) -> {
          effect.batch([
            route.push(route.Proposals(route.Show(proposal_id, option.None, 0))),
            effect.from(fn(_) { ffi.scroll_to_question(question_id:) }),
          ])
        }
      }
      let notification =
        list.find(model.notifications, fn(n) { n.id == notification_id })
      model
      |> model.empty_popup("all")
      |> case notification {
        Ok(n) -> model.set_notification(_, Notification(..n, read: True))
        Error(_) -> function.identity
      }
      |> pair.new(
        effect.batch([
          effs,
          user_effects.mark_notification_as_read(model, notification_id),
        ]),
      )
    }

    msg.ClosePopups(id) -> {
      model
      |> model.empty_popup(id)
      |> pair.new(model.unsubscribe_more_proposal(model))
    }

    msg.MarkNotificationAsRead(notification_id:) -> {
      let eff = user_effects.mark_notification_as_read(model, notification_id)
      case list.find(model.notifications, fn(n) { n.id == notification_id }) {
        Error(_) -> #(model, eff)
        Ok(notification) -> {
          Notification(..notification, read: True)
          |> model.set_notification(model, _)
          |> pair.new(eff)
        }
      }
    }

    msg.ToastUser(options:, content:) -> {
      #(model, toast.custom(options, content))
    }

    msg.UpsertQuestion(question:) -> {
      case dict.get(model.questions.by_proposal, question.proposal_id) {
        Error(_) ->
          [#(question.id, question)]
          |> model.set_questions(model, question.proposal_id, _)
          |> pair.new(effect.none())
        Ok(questions) -> {
          list.key_set(questions, question.id, question)
          |> model.set_questions(model, question.proposal_id, _)
          |> pair.new(effect.none())
        }
      }
    }

    msg.UserValidatedQuestion(question:) -> {
      case list.contains(model.running_requests, question.id) {
        True -> #(model, {
          use dispatch <- effect.from
          let retry = fn() { dispatch(msg.UserValidatedQuestion(question:)) }
          global.set_timeout(1000, retry)
          Nil
        })

        False -> {
          let question.Question(proposal_id:, ..) = question
          let found =
            dict.get(model.questions.by_proposal, proposal_id) |> result.is_ok
          use <- bool.guard(when: !found, return: #(model, effect.none()))
          let question = question.Question(..question, validated: True)
          update(model, msg.UpsertQuestion(question))
          |> pair.map_second(fn(e) {
            [e, question_effects.mark_question_as_validated(model, question)]
            |> effect.batch
          })
        }
      }
    }

    msg.UpdateTags(tags:) -> {
      use <- require_not_modifying_question(model)
      model.Model(..model, tags:)
      |> pair.new(effect.none())
    }

    msg.WebsocketReceivedMessage(value) -> {
      messages.decode_incoming_message(value)
      |> result.map(handle_received_message(model, _))
      |> result.unwrap(model)
      |> pair.new(effect.none())
    }

    msg.UserSelectedPage(page) -> {
      case model.route {
        route.Proposals(route.Show(pid, sheet, _)) ->
          route.replace(route.Proposals(route.Show(pid, sheet, page)))
        _ -> effect.none()
      }
      |> pair.new(model, _)
    }

    msg.None -> #(model, effect.none())
  }
  |> update.guard(with: model.replace_proposal_question_page)
}

fn handle_received_message(model: Model, msg: messages.ApiSentMessage) {
  case msg {
    messages.ApiSentProposalUpdated(proposal) ->
      model.upsert_proposals(model, [proposal])
    messages.ApiSentConnectorRegistered(connector, registered) ->
      msg.ApiReturnedConnectorData(connector, registered)
      |> connectors.handle_on_connectors(model, _)
      |> pair.first
    messages.ApiSentConnectorSettingsUpdated(connector_settings) ->
      msg.ApiReturnedConnectorSettings([connector_settings])
      |> connectors.handle_on_connectors(model, _)
      |> pair.first
    messages.ApiSentNotificationUpdated(notification) ->
      model.set_notification(model, notification)
    messages.ApiSentDataSourceUpdated(data_source) ->
      content_library.upsert_data_source(model.content_library, data_source)
      |> model.set_content_library(model, _)
    messages.ApiSentUserDataUpdated(user_id, _org_id, function) ->
      model.update_org_user(model, user_id, function)
    messages.ApiSentQuestionUpdated(question, data_sources, data_points) -> {
      let data_sources = dict.from_list([#(question.id, data_sources)])
      let data_points = dict.from_list([#(question.id, data_points)])
      model.upsert_proposal_question(model, question)
      |> model.add_questions_data_sources(data_sources)
      |> model.add_questions_data_points(data_points)
    }
  }
}

fn handle_xlsx(model: Model, msg: msg.Xlsx) {
  case msg {
    msg.DownloadXlsx(proposal_id:, toast_id:) -> {
      let proposal = model.proposals |> list.key_find(proposal_id)
      use proposal <- result.map(proposal)
      proposal_effects.download_xlsx(model, proposal, toast_id)
    }
    msg.GenerateXlsx(proposal_id:) -> {
      let proposal = list.key_find(model.proposals, proposal_id)
      use proposal <- result.map(proposal)
      let name = "\"" <> proposal.name <> "\""
      let generating = "Generating XLSX file for proposal " <> name <> "."
      let msg = [generating, "Please, wait…"] |> string.join("\n")
      toast.options()
      |> toast.sticky()
      |> toast.notify(fn(t) { msg.Xlsx(msg.DownloadXlsx(proposal_id, t)) })
      |> toast.custom(msg)
    }
  }
  |> result.unwrap(effect.none())
  |> pair.new(model, _)
}

fn view(model: Model, stylesheet: magic.StyleSheet) {
  use <- magic.render([stylesheet])
  case model.route, model.is_connected(model) {
    route.Login(..), _ -> login.invite()
    _, False -> login.view()
    route.SlackLogin(..), True -> slack_login.view(model)
    _, True -> layout.view(model)
  }
}
