/// <reference types="./model.d.mts" />
import * as $auth0 from "../../auth0_client/auth0/client.mjs";
import * as $birl from "../../birl/birl.mjs";
import * as $connector from "../../common/data/connector.mjs";
import * as $confluence from "../../common/data/connector/confluence.mjs";
import * as $notion from "../../common/data/connector/notion.mjs";
import * as $connector_settings from "../../common/data/connector_settings.mjs";
import * as $copilot_question from "../../common/data/copilot_question.mjs";
import * as $data_point from "../../common/data/data_point.mjs";
import * as $data_source from "../../common/data/data_source.mjs";
import * as $notification from "../../common/data/notification.mjs";
import * as $proposal from "../../common/data/proposal.mjs";
import * as $question from "../../common/data/question.mjs";
import * as $tag from "../../common/data/tag.mjs";
import * as $user from "../../common/data/user.mjs";
import * as $bool from "../../gleam_stdlib/gleam/bool.mjs";
import * as $dict from "../../gleam_stdlib/gleam/dict.mjs";
import * as $dynamic from "../../gleam_stdlib/gleam/dynamic.mjs";
import * as $float from "../../gleam_stdlib/gleam/float.mjs";
import * as $int from "../../gleam_stdlib/gleam/int.mjs";
import * as $list from "../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../gleam_stdlib/gleam/option.mjs";
import * as $result from "../../gleam_stdlib/gleam/result.mjs";
import * as $effect from "../../lustre/lustre/effect.mjs";
import * as $content_library from "../data/content_library.mjs";
import * as $route from "../data/route.mjs";
import * as $copilot_input from "../data/ui/copilot_input.mjs";
import * as $loading from "../data/ui/loading.mjs";
import * as $new_proposal from "../data/ui/new_proposal.mjs";
import { readPermissions as read_permissions } from "../frontend.ffi.mjs";
import {
  Ok,
  Error,
  toList,
  prepend as listPrepend,
  CustomType as $CustomType,
  makeError,
  divideFloat,
  isEqual,
} from "../gleam.mjs";

export class Connectors extends $CustomType {
  constructor(fetched, confluence, confluence_opened, notion, notion_opened, google_folder_id, settings) {
    super();
    this.fetched = fetched;
    this.confluence = confluence;
    this.confluence_opened = confluence_opened;
    this.notion = notion;
    this.notion_opened = notion_opened;
    this.google_folder_id = google_folder_id;
    this.settings = settings;
  }
}

export class DeleteProposal extends $CustomType {
  constructor(id) {
    super();
    this.id = id;
  }
}

export class DeleteDataSource extends $CustomType {
  constructor(id) {
    super();
    this.id = id;
  }
}

export class DeleteQuestionInProposal extends $CustomType {
  constructor(proposal_id, question_id) {
    super();
    this.proposal_id = proposal_id;
    this.question_id = question_id;
  }
}

export class NoModal extends $CustomType {}

export class Questions extends $CustomType {
  constructor(by_proposal, data_sources, data_points) {
    super();
    this.by_proposal = by_proposal;
    this.data_sources = data_sources;
    this.data_points = data_points;
  }
}

export class Model extends $CustomType {
  constructor(access_token, client, collaborators_proposal_opened, connectors, content_library, copilot_input, displayed_questions, proposal_filters, copilot_threads, custom_rewording_input, display_modal, feed_opened, loading, more_proposal_opened, more_proposal_unsubscriber, new_proposal, filter_proposal_opened, notifications, notifications_unread, permissions, proposals, proposal_block_page_size, questions, route, running_requests, tags, user, users) {
    super();
    this.access_token = access_token;
    this.client = client;
    this.collaborators_proposal_opened = collaborators_proposal_opened;
    this.connectors = connectors;
    this.content_library = content_library;
    this.copilot_input = copilot_input;
    this.displayed_questions = displayed_questions;
    this.proposal_filters = proposal_filters;
    this.copilot_threads = copilot_threads;
    this.custom_rewording_input = custom_rewording_input;
    this.display_modal = display_modal;
    this.feed_opened = feed_opened;
    this.loading = loading;
    this.more_proposal_opened = more_proposal_opened;
    this.more_proposal_unsubscriber = more_proposal_unsubscriber;
    this.new_proposal = new_proposal;
    this.filter_proposal_opened = filter_proposal_opened;
    this.notifications = notifications;
    this.notifications_unread = notifications_unread;
    this.permissions = permissions;
    this.proposals = proposals;
    this.proposal_block_page_size = proposal_block_page_size;
    this.questions = questions;
    this.route = route;
    this.running_requests = running_requests;
    this.tags = tags;
    this.user = user;
    this.users = users;
  }
}

export function new$(client, route) {
  return new Model(
    new $option.None(),
    client,
    new $option.None(),
    new Connectors(
      toList([]),
      $confluence.new$(),
      false,
      $notion.new$(),
      false,
      "",
      toList([]),
    ),
    $content_library.new$(),
    $copilot_input.new$(),
    toList([]),
    new $proposal.ProposalFilters(new $proposal.AllStatus(), new $option.None()),
    toList([]),
    "",
    new NoModal(),
    false,
    $loading.new$(),
    new $option.None(),
    new $option.None(),
    $new_proposal.init(),
    false,
    toList([]),
    false,
    toList([]),
    toList([]),
    10,
    new Questions($dict.new$(), $dict.new$(), $dict.new$()),
    route,
    toList([]),
    toList([]),
    new $option.None(),
    toList([]),
  );
}

function remove_same_id(val, id) {
  if (val instanceof $option.Some && (isEqual(val[0], id))) {
    let old_id = val[0];
    return new $option.None();
  } else {
    let value = val;
    return value;
  }
}

function clear_popup(model) {
  return model.withFields({
    feed_opened: false,
    collaborators_proposal_opened: new $option.None(),
    more_proposal_opened: new $option.None(),
    content_library: model.content_library.withFields({ selected: $dict.new$() }),
    filter_proposal_opened: false
  });
}

export function empty_popup(model, id) {
  let is_feed = id === "feed";
  return $bool.guard(
    is_feed,
    model.withFields({ feed_opened: false }),
    () => {
      return $bool.lazy_guard(
        id === "all",
        () => { return clear_popup(model); },
        () => {
          if (id.startsWith("collaborator-")) {
            let id$1 = id.slice(13);
            let collaborators_proposal_opened = remove_same_id(
              model.collaborators_proposal_opened,
              id$1,
            );
            return model.withFields({
              collaborators_proposal_opened: collaborators_proposal_opened
            });
          } else if (id.startsWith("ai-")) {
            let id$1 = id.slice(3);
            let more_proposal_opened = remove_same_id(
              model.more_proposal_opened,
              id$1,
            );
            return model.withFields({
              more_proposal_opened: more_proposal_opened
            });
          } else if (id === "proposal-filter") {
            return model.withFields({ filter_proposal_opened: false });
          } else {
            return model;
          }
        },
      );
    },
  );
}

export function mark_as_loaded(model, mapper) {
  return model.withFields({ loading: mapper(model.loading) });
}

export function update_access_token(model, access_token) {
  let _pipe = read_permissions(access_token);
  let _pipe$1 = $result.replace_error(_pipe, toList([]));
  let _pipe$2 = $result.then$(_pipe$1, $dynamic.list($dynamic.string));
  let _pipe$3 = $result.unwrap(_pipe$2, toList([]));
  return ((permissions) => {
    return model.withFields({
      access_token: new $option.Some(access_token),
      permissions: permissions
    });
  })(_pipe$3);
}

export function update_connected_user(model, user) {
  return model.withFields({ user: new $option.Some(user) });
}

export function is_connected(model) {
  return $option.is_some(model.access_token);
}

export function update_route(model, route) {
  return model.withFields({ route: route });
}

export function update_copilot_input(model, value) {
  let copilot_input = model.copilot_input.withFields({ value: value });
  return model.withFields({ copilot_input: copilot_input });
}

export function update_custom_rewording_input(model, value) {
  let custom_rewording_input = value;
  return model.withFields({ custom_rewording_input: custom_rewording_input });
}

export function replace_proposal_question_page(model) {
  let is_loading = model.loading.questions;
  let $ = model.route;
  if ($ instanceof $route.Proposals &&
  $[0] instanceof $route.Show &&
  (!is_loading)) {
    let id = $[0].id;
    let sheet = $[0].sheet;
    let page = $[0].page;
    let $1 = (() => {
      let _pipe = $dict.get(model.questions.by_proposal, id);
      let _pipe$1 = $result.unwrap(_pipe, toList([]));
      let _pipe$2 = $list.length(_pipe$1);
      return $int.divide(_pipe$2, model.proposal_block_page_size);
    })();
    if (!$1.isOk()) {
      throw makeError(
        "let_assert",
        "data/model",
        226,
        "replace_proposal_question_page",
        "Pattern match failed, no pattern matched the value.",
        { value: $1 }
      )
    }
    let page_max = $1[0];
    return $bool.guard(
      page <= page_max,
      $effect.none(),
      () => {
        return $route.replace(
          new $route.Proposals(new $route.Show(id, sheet, page_max)),
        );
      },
    );
  } else {
    return $effect.none();
  }
}

export function add_proposal_questions(model, questions) {
  let $ = $list.first(questions);
  if (!$.isOk()) {
    return model;
  } else {
    let question = $[0];
    let questions$1 = $list.map(questions, (q) => { return [q.id, q]; });
    let _pipe = model.questions.by_proposal;
    let _pipe$1 = $dict.insert(_pipe, question.proposal_id, questions$1);
    return ((by_proposal) => {
      return model.withFields({
        questions: model.questions.withFields({ by_proposal: by_proposal })
      });
    })(_pipe$1);
  }
}

function upsert(qs, question) {
  if (qs.atLeastLength(1)) {
    let q = qs.head;
    let rest = qs.tail;
    let $ = q.id === question.id;
    if ($) {
      return listPrepend(question, rest);
    } else {
      return listPrepend(q, upsert(rest, question));
    }
  } else {
    return toList([question]);
  }
}

export function add_copilot_questions(model, questions) {
  let _pipe = $list.fold(
    questions,
    model.copilot_threads,
    (threads, question) => {
      let q = (() => {
        let _pipe = $list.key_find(threads, question.thread_id);
        return $result.unwrap(_pipe, toList([]));
      })();
      let _pipe = upsert(q, question);
      let _pipe$1 = $list.sort(
        _pipe,
        (a, b) => { return $birl.compare(a.created_at, b.created_at); },
      );
      return ((_capture) => {
        return $list.key_set(threads, question.thread_id, _capture);
      })(_pipe$1);
    },
  );
  return ((copilot_threads) => {
    return model.withFields({ copilot_threads: copilot_threads });
  })(_pipe);
}

export function reset_copilot_input(model) {
  return model.withFields({ copilot_input: $copilot_input.new$() });
}

export function reset_custom_rewording_input(model) {
  return model.withFields({ custom_rewording_input: "" });
}

export function add_questions_data_sources(model, data_sources) {
  let _pipe = model.questions.data_sources;
  let _pipe$1 = $dict.merge(_pipe, data_sources);
  return ((data_sources) => {
    return model.withFields({
      questions: model.questions.withFields({ data_sources: data_sources })
    });
  })(_pipe$1);
}

export function add_questions_data_points(model, data_points) {
  let _pipe = model.questions.data_points;
  let _pipe$1 = $dict.merge(_pipe, data_points);
  return ((data_points) => {
    return model.withFields({
      questions: model.questions.withFields({ data_points: data_points })
    });
  })(_pipe$1);
}

export function empty_new_proposal(model) {
  let $ = model.route;
  if ($ instanceof $route.Proposals &&
  $[0] instanceof $route.Add &&
  !$[0].loading) {
    return model.withFields({ new_proposal: $new_proposal.init() });
  } else {
    return model;
  }
}

export function set_new_proposal(model, new_proposal) {
  return model.withFields({ new_proposal: new_proposal });
}

export function set_content_library(model, content_library) {
  return model.withFields({ content_library: content_library });
}

export function set_connectors(model, connectors) {
  return model.withFields({ connectors: connectors });
}

function count_validated_questions(questions) {
  return $list.fold(
    questions,
    [0, 0],
    (_use0, _use1) => {
      let validated = _use0[0];
      let total = _use0[1];
      let question = _use1[1];
      let $ = question.validated;
      if ($) {
        return [validated + 1, total + 1];
      } else {
        return [validated, total + 1];
      }
    },
  );
}

function compute_proposal_progress(questions) {
  let $ = count_validated_questions(questions);
  let validated = $[0];
  let total = $[1];
  let _pipe = (divideFloat(
    $int.to_float(validated),
    $int.to_float($int.max(total, 1))
  ));
  let _pipe$1 = $float.multiply(_pipe, 100.0);
  return $float.round(_pipe$1);
}

export function find_question(model, proposal_id, question_id) {
  let _pipe = model.questions.by_proposal;
  let _pipe$1 = $dict.get(_pipe, proposal_id);
  return $result.then$(
    _pipe$1,
    (_capture) => { return $list.key_find(_capture, question_id); },
  );
}

export function remove_question(model, proposal_id, question_id) {
  let by_proposal = (() => {
    let _pipe = model.questions.by_proposal;
    let _pipe$1 = $dict.get(_pipe, proposal_id);
    let _pipe$2 = $result.map(
      _pipe$1,
      (_capture) => {
        return $list.filter(_capture, (q) => { return q[0] !== question_id; });
      },
    );
    let _pipe$3 = $result.map(
      _pipe$2,
      (_capture) => {
        return $dict.insert(model.questions.by_proposal, proposal_id, _capture);
      },
    );
    return $result.unwrap(_pipe$3, model.questions.by_proposal);
  })();
  let progress = (() => {
    let _pipe = by_proposal;
    let _pipe$1 = $dict.get(_pipe, proposal_id);
    let _pipe$2 = $result.unwrap(_pipe$1, toList([]));
    return compute_proposal_progress(_pipe$2);
  })();
  let proposals = (() => {
    let _pipe = model.proposals;
    let _pipe$1 = $list.key_find(_pipe, proposal_id);
    let _pipe$2 = $result.map(
      _pipe$1,
      (p) => { return p.withFields({ progress: progress }); },
    );
    let _pipe$3 = $result.map(
      _pipe$2,
      (_capture) => {
        return $list.key_set(model.proposals, proposal_id, _capture);
      },
    );
    return $result.unwrap(_pipe$3, model.proposals);
  })();
  let questions = model.questions.withFields({ by_proposal: by_proposal });
  return model.withFields({ questions: questions, proposals: proposals });
}

function upsert_notification(notifications, notification) {
  if (notifications.atLeastLength(1)) {
    let n = notifications.head;
    let rest = notifications.tail;
    let $ = n.id === notification.id;
    if ($) {
      return new Ok(listPrepend(notification, rest));
    } else {
      let _pipe = upsert_notification(rest, notification);
      return $result.map(_pipe, (value) => { return listPrepend(n, value); });
    }
  } else {
    return new Error(undefined);
  }
}

export function set_notifications(model, notifications) {
  let notifications_unread = $list.any(
    notifications,
    (n) => { return !n.read; },
  );
  return model.withFields({
    notifications: notifications,
    notifications_unread: notifications_unread
  });
}

export function set_notification(model, notification) {
  let _pipe = upsert_notification(model.notifications, notification);
  let _pipe$1 = $result.lazy_unwrap(
    _pipe,
    () => { return listPrepend(notification, model.notifications); },
  );
  return ((_capture) => { return set_notifications(model, _capture); })(_pipe$1);
}

export function find_current_user(model) {
  return $list.find(
    model.users,
    (user) => {
      let connected_user = $option.map(model.user, (u) => { return u.sub; });
      return isEqual(new $option.Some(user.id), connected_user);
    },
  );
}

export function update_org_user(model, user_id, function$) {
  let _pipe = model.users;
  let _pipe$1 = $list.map(
    _pipe,
    (user) => {
      return $bool.guard(
        user.id !== user_id,
        user,
        () => {
          return user.withFields({ function: new $option.Some(function$) });
        },
      );
    },
  );
  return ((users) => { return model.withFields({ users: users }); })(_pipe$1);
}

export function find_current_user_id(model) {
  let user = find_current_user(model);
  return $result.map(user, (u) => { return u.id; });
}

export function unsubscribe_more_proposal(model) {
  return $effect.from(
    (_) => {
      let unsub = $option.unwrap(
        model.more_proposal_unsubscriber,
        () => { return undefined; },
      );
      return unsub();
    },
  );
}

export function upsert_proposals(model, proposals) {
  let _pipe = $list.fold(
    proposals,
    model.proposals,
    (proposals, proposal) => {
      return $list.key_set(proposals, proposal.id, proposal);
    },
  );
  let _pipe$1 = ((proposals) => {
    return model.withFields({ proposals: proposals });
  })(_pipe);
  return mark_as_loaded(_pipe$1, $loading.proposals);
}

export function filter_questions_on_status(questions, status) {
  return $list.filter(
    questions,
    (_use0) => {
      let question_obj = _use0[1];
      if (status instanceof $proposal.Validated) {
        return question_obj.validated;
      } else if (status instanceof $proposal.InReview) {
        return !question_obj.validated;
      } else {
        return true;
      }
    },
  );
}

export function filter_questions_on_owner(questions, owner) {
  return $bool.guard(
    $option.is_none(owner),
    questions,
    () => {
      return $list.filter(
        questions,
        (_use0) => {
          let question = _use0[1];
          return isEqual(question.owner, owner);
        },
      );
    },
  );
}

export function compute_displayed_questions(questions, filters) {
  let _pipe = filter_questions_on_status(questions, filters.status);
  return filter_questions_on_owner(_pipe, filters.owner);
}

export function set_questions(model, proposal_id, questions) {
  let progress = compute_proposal_progress(questions);
  let by_proposal = $dict.insert(
    model.questions.by_proposal,
    proposal_id,
    questions,
  );
  let displayed_questions = compute_displayed_questions(
    questions,
    model.proposal_filters,
  );
  let questions$1 = model.questions.withFields({ by_proposal: by_proposal });
  let proposals = (() => {
    let _pipe = model.proposals;
    let _pipe$1 = $list.key_find(_pipe, proposal_id);
    let _pipe$2 = $result.map(
      _pipe$1,
      (p) => { return p.withFields({ progress: progress }); },
    );
    let _pipe$3 = $result.map(
      _pipe$2,
      (_capture) => {
        return $list.key_set(model.proposals, proposal_id, _capture);
      },
    );
    return $result.unwrap(_pipe$3, model.proposals);
  })();
  return model.withFields({
    questions: questions$1,
    proposals: proposals,
    displayed_questions: displayed_questions
  });
}

export function upsert_proposal_question(model, question) {
  let _pipe = model.questions.by_proposal;
  let _pipe$1 = $dict.get(_pipe, question.proposal_id);
  let _pipe$2 = $result.unwrap(_pipe$1, toList([]));
  let _pipe$3 = $list.key_set(_pipe$2, question.id, question);
  return ((_capture) => {
    return set_questions(model, question.proposal_id, _capture);
  })(_pipe$3);
}

export function set_proposal_filters(model, proposal_filters) {
  return model.withFields({ proposal_filters: proposal_filters });
}

export function set_displayed_questions(model, displayed_questions) {
  return model.withFields({ displayed_questions: displayed_questions });
}

export function get_current_proposal_questions(model, proposal_id) {
  let _pipe = model.questions.by_proposal;
  let _pipe$1 = $dict.get(_pipe, proposal_id);
  return $result.unwrap(_pipe$1, toList([]));
}

export function recompute_displayed_questions(model, proposal_id) {
  let _pipe = get_current_proposal_questions(model, proposal_id);
  let _pipe$1 = compute_displayed_questions(_pipe, model.proposal_filters);
  return ((_capture) => { return set_displayed_questions(model, _capture); })(
    _pipe$1,
  );
}

export function toggle_filter_proposal_opened(model) {
  return model.withFields({
    filter_proposal_opened: !model.filter_proposal_opened
  });
}

export function close_filter_proposal(model) {
  return model.withFields({ filter_proposal_opened: false });
}
