/// <reference types="./show.d.mts" />
import * as $birl from "../../../birl/birl.mjs";
import * as $data_source from "../../../common/data/data_source.mjs";
import * as $user from "../../../common/data/user.mjs";
import * as $function from "../../../gleam_stdlib/gleam/function.mjs";
import * as $int from "../../../gleam_stdlib/gleam/int.mjs";
import * as $list from "../../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../../gleam_stdlib/gleam/option.mjs";
import * as $order from "../../../gleam_stdlib/gleam/order.mjs";
import * as $pair from "../../../gleam_stdlib/gleam/pair.mjs";
import * as $result from "../../../gleam_stdlib/gleam/result.mjs";
import * as $string from "../../../gleam_stdlib/gleam/string.mjs";
import * as $a from "../../../lustre/lustre/attribute.mjs";
import * as $el from "../../../lustre/lustre/element.mjs";
import * as $h from "../../../lustre/lustre/element/html.mjs";
import * as $e from "../../../lustre/lustre/event.mjs";
import * as $content_library from "../../data/content_library.mjs";
import * as $model from "../../data/model.mjs";
import * as $msg from "../../data/msg.mjs";
import * as $button from "../../design_system/components/button.mjs";
import * as $checkbox from "../../design_system/components/checkbox.mjs";
import * as $input from "../../design_system/components/input.mjs";
import * as $label from "../../design_system/components/label.mjs";
import * as $layout from "../../design_system/components/layout.mjs";
import * as $pp from "../../design_system/components/profile_picture.mjs";
import * as $skeleton from "../../design_system/components/skeleton.mjs";
import * as $table from "../../design_system/components/table.mjs";
import * as $icons from "../../design_system/icons.mjs";
import { toList, prepend as listPrepend, isEqual } from "../../gleam.mjs";
import * as $utils from "../../utils.mjs";
import * as $common from "../../view/common.mjs";
import * as $cl_common from "../../view/content_library/common.mjs";
import * as $s from "../../view/styles/content_library.mjs";
import * as $ps from "../../view/styles/proposals.mjs";

function state_card(color, count, status, active) {
  let content = (() => {
    if (status instanceof $data_source.Verified) {
      return "Verified";
    } else if (status instanceof $data_source.NotVerified) {
      return "Not verified";
    } else if (status instanceof $data_source.Pending) {
      return "Pending";
    } else if (status instanceof $data_source.Outdated) {
      return "Outdated";
    } else {
      return "??";
    }
  })();
  let handler = $e.on_click(
    new $msg.ContentLibrary(new $msg.UserSelectedFilter(status)),
  );
  return $s.filter_card(
    $cl_common.choose_settings(color, active),
    toList([handler]),
    toList([
      $s.filter_card_title(toList([]), toList([$h.text($int.to_string(count))])),
      $s.filter_card_subtitle(toList([]), toList([$h.text(content)])),
    ]),
  );
}

function content_library_filters(model) {
  return $layout.row(
    toList([$layout.gap(32), $layout.justify("space-between")]),
    toList([]),
    toList([
      $layout.row(
        toList([$layout.gap(16), $layout.align("center")]),
        toList([]),
        toList([
          $input.input(
            toList([
              $input.placeholder("Search"),
              $input.icon($icons.magnifying_glass()),
              $input.value(model.content_library.search),
              $input.on_input(
                (var0) => { return new $msg.UserUpdatedSearch(var0); },
              ),
            ]),
          ),
        ]),
      ),
      $h.label(
        toList([]),
        toList([
          $ps.main_file_input(
            toList([]),
            toList([$button.icon($icons.add()), $h.text("Select files")]),
          ),
          $h.input(
            toList([
              $a.type_("file"),
              $a.attribute("multiple", "true"),
              $a.style(toList([["display", "none"]])),
              $utils.on_files_change(
                (var0) => { return new $msg.UserUploadedSourceFiles(var0); },
              ),
            ]),
          ),
        ]),
      ),
    ]),
  );
}

function view_loading_table() {
  let _pipe = $table.row(
    listPrepend(
      $table.cell($el.none()),
      (() => {
        let _pipe = $table.cell($skeleton.skeleton(100, 25));
        return $list.repeat(_pipe, 7);
      })(),
    ),
  );
  let _pipe$1 = $list.repeat(_pipe, 5);
  return $table.body(_pipe$1);
}

function content_library_table_headers() {
  return $table.headers(
    toList([
      (() => {
        let _pipe = $table.header("");
        return $table.fit(_pipe);
      })(),
      (() => {
        let _pipe = $table.header("Format");
        return $table.fit(_pipe);
      })(),
      $table.header("Name"),
      (() => {
        let _pipe = $table.header("Language");
        return $table.fit(_pipe);
      })(),
      (() => {
        let _pipe = $table.header("Status");
        return $table.fit(_pipe);
      })(),
      $table.header("Owner"),
      (() => {
        let _pipe = $table.header("Expiration Date");
        return $table.fit(_pipe);
      })(),
      $table.header("Tags"),
      (() => {
        let _pipe = $table.header("");
        return $table.fit(_pipe);
      })(),
    ]),
  );
}

function by_search(search, s) {
  return $string.contains(
    $string.lowercase(s.display.name),
    $string.lowercase(search),
  );
}

function view_table_body(model) {
  return $table.body(
    $list.map(
      (() => {
        let _pipe = model.content_library;
        let _pipe$1 = $content_library.filtered_non_qna_sources(_pipe);
        return (() => {
          let $ = model.content_library.search;
          if ($ === "") {
            return $function.identity;
          } else {
            let search = $;
            return (_capture) => {
              return $list.filter(
                _capture,
                (_capture) => { return by_search(search, _capture); },
              );
            };
          }
        })()(_pipe$1);
      })(),
      (source) => {
        let user = $list.find(
          model.users,
          (user) => { return isEqual(new $option.Some(user.id), source.owner); },
        );
        let _pipe = $table.row(
          toList([
            (() => {
              let _pipe = $table.cell(
                $checkbox.checkbox(
                  $checkbox.checked(
                    $content_library.is_selected(
                      model.content_library,
                      source.id,
                    ),
                  ),
                  (checked) => {
                    return new $msg.ContentLibrary(
                      new $msg.UserSelectedSource(source.id, checked),
                    );
                  },
                ),
              );
              return $table.stop_cell_propagation(_pipe);
            })(),
            $table.cell(
              $common.small_icon($common.select_icon(source.display.mime_type)),
            ),
            $table.cell($el.text(source.display.name)),
            (() => {
              let _pipe = $table.cell(
                $h.div(
                  toList([]),
                  toList([
                    $h.text(
                      (() => {
                        let $ = source.display.language;
                        if ($ instanceof $option.None) {
                          return "";
                        } else if ($ instanceof $option.Some && $[0] === "") {
                          return "";
                        } else {
                          let language = $[0];
                          return $content_library.from_iso_string_to_display_string(
                            language,
                          );
                        }
                      })(),
                    ),
                  ]),
                ),
              );
              return $table.justify(_pipe, "start");
            })(),
            $table.cell(
              (() => {
                let $ = (() => {
                  let $1 = source.status;
                  if ($1 instanceof $data_source.Pending) {
                    return [new $label.Current(), "Pending"];
                  } else if ($1 instanceof $data_source.NotVerified) {
                    return [new $label.Neutral(), "Not verified"];
                  } else if ($1 instanceof $data_source.Verified) {
                    return [new $label.Success(), "Verified"];
                  } else if ($1 instanceof $data_source.Outdated) {
                    return [new $label.Error(), "Outdated"];
                  } else {
                    return [new $label.Error(), "Archived"];
                  }
                })();
                let status = $[0];
                let content = $[1];
                return $label.label(status, content);
              })(),
            ),
            $table.cell(
              $common.name_small(
                toList([]),
                (() => {
                  if (!user.isOk()) {
                    return toList([$el.text("")]);
                  } else {
                    let name = user[0].name;
                    let picture = user[0].picture;
                    let initials = user[0].initials;
                    return toList([
                      $pp.from_user(picture, initials, new $pp.Medium()),
                      $el.text(name),
                    ]);
                  }
                })(),
              ),
            ),
            $table.cell(
              (() => {
                let $ = (() => {
                  let $1 = source.expiration_date;
                  if ($1 instanceof $option.None) {
                    return [new $label.Neutral(), "None"];
                  } else {
                    let expiration_date = $1[0];
                    let _pipe = (() => {
                      let $2 = $birl.compare(expiration_date, $birl.utc_now());
                      if ($2 instanceof $order.Gt) {
                        return new $label.Success();
                      } else if ($2 instanceof $order.Eq) {
                        return new $label.Success();
                      } else {
                        return new $label.Error();
                      }
                    })();
                    return $pair.new$(
                      _pipe,
                      $utils.time_to_qualified_day(expiration_date, "/"),
                    );
                  }
                })();
                let status = $[0];
                let content = $[1];
                return $label.label(status, content);
              })(),
            ),
            $table.cell(
              $layout.row(
                toList([$layout.gap(8)]),
                toList([]),
                $list.filter_map(
                  source.tags,
                  (tag) => {
                    let tag$1 = $list.find(
                      model.tags,
                      (t) => { return t.id === tag; },
                    );
                    return $result.map(
                      tag$1,
                      (tag) => {
                        return $label.label(new $label.Neutral(), tag.name);
                      },
                    );
                  },
                ),
              ),
            ),
            (() => {
              let _pipe = $table.cell(
                $ps.trash_icon_button(
                  toList([
                    $e.on_click(
                      new $msg.UserDisplayedDeleteDataSourceModal(source.id),
                    ),
                  ]),
                  toList([$ps.trash_icon_child()]),
                ),
              );
              let _pipe$1 = $table.justify(_pipe, "end");
              return $table.stop_cell_propagation(_pipe$1);
            })(),
          ]),
        );
        return $table.on_click(
          _pipe,
          new $msg.OpenLink(
            (() => {
              let _pipe$1 = source.display.origin;
              return $option.map(
                _pipe$1,
                (_capture) => {
                  return $pair.new$(source.display.name, _capture);
                },
              );
            })(),
          ),
        );
      },
    ),
  );
}

const empty_text = "Connect your Drive, or upload directly files, directly with the button above.";

function empty_cell() {
  return $s.empty_cell($s.empty_text_content($h.text(empty_text)));
}

function view_empty_table() {
  return $table.body(
    toList([
      (() => {
        let _pipe = $table.row(
          toList([
            (() => {
              let _pipe = $table.cell(empty_cell());
              return $table.span(_pipe, 7);
            })(),
          ]),
        );
        return $table.hover(_pipe, false);
      })(),
    ]),
  );
}

function content_library_table(model) {
  return $table.table(
    toList([
      content_library_table_headers(),
      (() => {
        let $ = model.loading.content_library;
        let $1 = $list.is_empty(model.content_library.non_qna_sources.all);
        if ($) {
          return view_loading_table();
        } else if ($1) {
          return view_empty_table();
        } else {
          return view_table_body(model);
        }
      })(),
    ]),
  );
}

export function view(model) {
  return $el.fragment(
    toList([
      $layout.row(
        toList([$layout.gap(32)]),
        toList([]),
        toList([
          state_card(
            "green",
            model.content_library.non_qna_status.verified,
            new $data_source.Verified(),
            isEqual(
              new $option.Some(new $data_source.Verified()),
              model.content_library.filter_selected
            ),
          ),
          state_card(
            "blue",
            model.content_library.non_qna_status.pending,
            new $data_source.Pending(),
            isEqual(
              new $option.Some(new $data_source.Pending()),
              model.content_library.filter_selected
            ),
          ),
          state_card(
            "grey",
            model.content_library.non_qna_status.not_verified,
            new $data_source.NotVerified(),
            isEqual(
              new $option.Some(new $data_source.NotVerified()),
              model.content_library.filter_selected
            ),
          ),
          state_card(
            "red",
            model.content_library.non_qna_status.outdated,
            new $data_source.Outdated(),
            isEqual(
              new $option.Some(new $data_source.Outdated()),
              model.content_library.filter_selected
            ),
          ),
        ]),
      ),
      (() => {
        let _pipe = content_library_filters(model);
        return $el.map(
          _pipe,
          (var0) => { return new $msg.ContentLibrary(var0); },
        );
      })(),
      content_library_table(model),
    ]),
  );
}
