/// <reference types="./dropdown.d.mts" />
import * as $option from "../../../../gleam_stdlib/gleam/option.mjs";
import * as $s from "../../../../sketch/sketch.mjs";
import * as $size from "../../../../sketch/sketch/size.mjs";
import { percent, px, vh } from "../../../../sketch/sketch/size.mjs";
import * as $h from "../../../../sketch_magic/sketch/magic/element/html.mjs";
import * as $colors from "../../../design_system/colors.mjs";
import * as $shadows from "../../../design_system/shadows.mjs";
import * as $text from "../../../design_system/typography/text.mjs";
import { toList } from "../../../gleam.mjs";

export function full_wrapper(attrs, children) {
  let _pipe = $s.class$(
    toList([
      $s.display("flex"),
      $s.flex_direction("column"),
      $s.position("relative"),
      $s.width(percent(100)),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function wrapper(attrs, children) {
  let _pipe = $s.class$(
    toList([
      $s.compose($text.sm($text.medium)),
      $s.display("flex"),
      $s.flex_direction("column"),
      $s.align_items("start"),
      $s.gap(px(6)),
      $s.width(percent(100)),
    ]),
  );
  return $h.label(_pipe, attrs, children);
}

function dropdown_base() {
  return $s.class$(
    toList([
      $s.compose($text.md($text.medium)),
      $s.appearance("none"),
      $s.display("flex"),
      $s.align_items("center"),
      $s.justify_content("space-between"),
      $s.background($colors.light.white),
      $s.border("1px solid " + $colors.light.grey_400),
      $s.border_radius(px(8)),
      $s.gap(px(8)),
      $s.padding_("10px 14px"),
      $s.cursor("pointer"),
      $s.position("relative"),
      $s.transition("all .3s"),
      $s.white_space("nowrap"),
    ]),
  );
}

export function standard(input_class, attrs, children) {
  let _pipe = $s.class$(
    toList([
      $s.compose(dropdown_base()),
      (() => {
        let _pipe = input_class;
        let _pipe$1 = $option.map(_pipe, $s.compose);
        return $option.lazy_unwrap(_pipe$1, $s.none);
      })(),
    ]),
  );
  return $h.button(_pipe, attrs, children);
}

export function disabled(input_class, attrs, children) {
  let _pipe = $s.class$(
    toList([
      $s.compose(dropdown_base()),
      (() => {
        let _pipe = input_class;
        let _pipe$1 = $option.map(_pipe, $s.compose);
        return $option.lazy_unwrap(_pipe$1, $s.none);
      })(),
    ]),
  );
  return $h.button(_pipe, attrs, children);
}

export function active(input_class, attrs, children) {
  let _pipe = $s.class$(
    toList([
      $s.box_shadow("0px 0px 0px 4px " + $colors.light.grey_100),
      $s.compose(dropdown_base()),
      (() => {
        let _pipe = input_class;
        let _pipe$1 = $option.map(_pipe, $s.compose);
        return $option.lazy_unwrap(_pipe$1, $s.none);
      })(),
    ]),
  );
  return $h.button(_pipe, attrs, children);
}

export function text_icon_wrapper(active, attrs, children) {
  let _pipe = $s.class$(
    toList([
      $s.compose($text.sm($text.medium)),
      $s.display("flex"),
      $s.align_items("center"),
      $s.gap(px(8)),
      $s.color(
        ("var(--text-color, " + (() => {
          if (active) {
            return $colors.light.onyx;
          } else {
            return $colors.light.grey_800;
          }
        })()) + ")",
      ),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function choice_icon_wrapper(attrs, children) {
  let _pipe = $s.class$(
    toList([
      $s.compose($text.sm($text.medium)),
      $s.display("flex"),
      $s.align_items("center"),
      $s.gap(px(8)),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function full_icon_wrapper(child) {
  let _pipe = $s.class$(
    toList([
      $s.display("flex"),
      $s.align_items("center"),
      $s.justify_content("center"),
      $s.width(px(20)),
      $s.height(px(20)),
    ]),
  );
  return $h.div(_pipe, toList([]), toList([child]));
}

export function children_wrapper(panel_class, attrs, children) {
  let _pipe = $s.class$(
    toList([
      $s.display("flex"),
      $s.flex_direction("column"),
      $s.background($colors.light.white),
      $s.border("1px solid " + $colors.light.grey_400),
      $s.box_shadow($shadows.m()),
      $s.border_radius(px(8)),
      $s.max_height(vh(30)),
      $s.min_width_("fit-content"),
      $s.overflow("auto"),
      $s.z_index(100_000_000_000),
      (() => {
        let _pipe = panel_class;
        let _pipe$1 = $option.map(_pipe, $s.compose);
        return $option.lazy_unwrap(_pipe$1, $s.none);
      })(),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function choice_wrapper(class$, attrs, children) {
  let _pipe = $s.class$(
    toList([
      $s.color($colors.light.onyx),
      $s.display("flex"),
      $s.align_items("center"),
      $s.justify_content("space-between"),
      $s.padding_("10px 14px"),
      $s.gap(px(10)),
      $s.cursor("pointer"),
      $s.hover(toList([$s.background($colors.light.grey_200)])),
      (() => {
        let _pipe = class$;
        let _pipe$1 = $option.map(_pipe, $s.compose);
        return $option.lazy_unwrap(_pipe$1, $s.none);
      })(),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function icon_wrapper(attrs, children) {
  return $h.div(
    $s.class$(toList([$s.width(px(20)), $s.height(px(20))])),
    attrs,
    children,
  );
}

export function section_wrapper(class$, attrs, children) {
  let _pipe = $s.class$(
    toList([
      $s.compose($text.xs($text.medium)),
      $s.color($colors.light.grey_800),
      $s.position("sticky"),
      $s.top(px(0)),
      $s.padding(px(10)),
      $s.background($colors.light.white),
      $s.border_bottom("1px solid " + $colors.light.grey_400),
      (() => {
        let _pipe = class$;
        let _pipe$1 = $option.map(_pipe, $s.compose);
        return $option.lazy_unwrap(_pipe$1, $s.none);
      })(),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}
