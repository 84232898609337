/// <reference types="./connector.d.mts" />
import * as $json from "../../gleam_json/gleam/json.mjs";
import * as $dynamic from "../../gleam_stdlib/gleam/dynamic.mjs";
import * as $result from "../../gleam_stdlib/gleam/result.mjs";
import { Ok, Error, toList, CustomType as $CustomType } from "../gleam.mjs";

export class GoogleDrive extends $CustomType {}

export class Confluence extends $CustomType {}

export class Notion extends $CustomType {}

export class OneDrive extends $CustomType {}

export class Slack extends $CustomType {}

export class Steerlab extends $CustomType {}

export class SteerlabQna extends $CustomType {}

export function to_string(connector) {
  if (connector instanceof GoogleDrive) {
    return "google-drive";
  } else if (connector instanceof Confluence) {
    return "confluence";
  } else if (connector instanceof Notion) {
    return "notion";
  } else if (connector instanceof OneDrive) {
    return "one-drive";
  } else if (connector instanceof Slack) {
    return "slack";
  } else if (connector instanceof Steerlab) {
    return "steerlab";
  } else {
    return "steerlab-qna";
  }
}

export function from_string(connector) {
  if (connector === "google-drive") {
    return new Ok(new GoogleDrive());
  } else if (connector === "confluence") {
    return new Ok(new Confluence());
  } else if (connector === "notion") {
    return new Ok(new Notion());
  } else if (connector === "one-drive") {
    return new Ok(new OneDrive());
  } else if (connector === "slack") {
    return new Ok(new Slack());
  } else if (connector === "steerlab") {
    return new Ok(new Steerlab());
  } else if (connector === "steerlab-qna") {
    return new Ok(new SteerlabQna());
  } else {
    return new Error(undefined);
  }
}

export function encode(connector) {
  return $json.string(to_string(connector));
}

export function decode(dyn) {
  let _pipe = $dynamic.string(dyn);
  return $result.then$(
    _pipe,
    (str) => {
      let _pipe$1 = from_string(str);
      return $result.replace_error(_pipe$1, toList([]));
    },
  );
}
