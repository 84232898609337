/// <reference types="./proposals.d.mts" />
import * as $int from "../../../gleam_stdlib/gleam/int.mjs";
import * as $s from "../../../sketch/sketch.mjs";
import * as $size from "../../../sketch/sketch/size.mjs";
import { percent, px } from "../../../sketch/sketch/size.mjs";
import * as $h from "../../../sketch_magic/sketch/magic/element/html.mjs";
import * as $colors from "../../design_system/colors.mjs";
import * as $button from "../../design_system/components/button.mjs";
import * as $outline from "../../design_system/outline.mjs";
import * as $shadows from "../../design_system/shadows.mjs";
import * as $display from "../../design_system/typography/display.mjs";
import * as $text from "../../design_system/typography/text.mjs";
import { toList, divideInt } from "../../gleam.mjs";

export function drag_and_drop_zone(attrs, children) {
  let _pipe = $s.class$(
    toList([
      $s.display("flex"),
      $s.padding(px(64)),
      $s.border_radius(px(8)),
      $s.border("1px dashed " + $colors.light.grey_400),
      $s.flex_direction("column"),
      $s.align_items("center"),
      $s.margin_("0 auto"),
      $s.color($colors.light.onyx),
      $s.gap(px(32)),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function icon_wrapper(attrs, children) {
  let _pipe = $s.class$(
    toList([
      $s.display("flex"),
      $s.align_items("center"),
      $s.justify_content("center"),
      $s.width(px(48)),
      $s.height(px(48)),
      $s.padding(px(12)),
      $s.background($colors.light.foam),
      $s.border_radius(px(24)),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function drop_title(attrs, children) {
  let _pipe = $s.class$(toList([$s.compose($display.xs())]));
  return $h.div(_pipe, attrs, children);
}

function layout_wrapper_() {
  return $s.class$(
    toList([
      $s.display("flex"),
      $s.flex_direction("row"),
      $s.margin_("0 auto"),
      $s.width(percent(100)),
      $s.max_width(px(600)),
      $s.height(percent(100)),
    ]),
  );
}

export function small_icon_wrapper(child) {
  let _pipe = $s.class$(toList([$s.width(px(24)), $s.height(px(24))]));
  return $h.div(_pipe, toList([]), toList([child]));
}

export function details_wrapper(attrs, children) {
  let _pipe = $s.class$(toList([$s.compose(layout_wrapper_())]));
  return $h.form(_pipe, attrs, children);
}

export function layout_wrapper(attrs, children) {
  let _pipe = $s.class$(toList([$s.compose(layout_wrapper_())]));
  return $h.div(_pipe, attrs, children);
}

export function drop_subtitle(attrs, children) {
  let _pipe = $s.class$(toList([$s.compose($text.md($text.regular))]));
  return $h.div(_pipe, attrs, children);
}

export function details_title(attrs, children) {
  let _pipe = $s.class$(toList([$s.compose($display.xs())]));
  return $h.div(_pipe, attrs, children);
}

export function details_subtitle(attrs, children) {
  let _pipe = $s.class$(toList([$s.compose($text.md($text.regular))]));
  return $h.div(_pipe, attrs, children);
}

export function details_owner_label(attrs, children) {
  let _pipe = $s.class$(
    toList([$s.compose($text.sm($text.medium)), $s.color($colors.light.onyx)]),
  );
  return $h.div(_pipe, attrs, children);
}

export function details_owner_picture(attrs, children) {
  let _pipe = $s.class$(
    toList([
      $s.compose($text.sm($text.regular)),
      $s.color($colors.light.paragraph),
      $s.display("flex"),
      $s.gap(px(8)),
      $s.align_items("center"),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function file_input(attrs, children) {
  let _pipe = $s.class$(toList([$s.compose($button.button_secondary())]));
  return $h.div(_pipe, attrs, children);
}

export function main_file_input(attrs, children) {
  let _pipe = $s.class$(toList([$s.compose($button.button_primary())]));
  return $h.div(_pipe, attrs, children);
}

export function explanatory_text(attrs, children) {
  let _pipe = $s.class$(toList([$s.compose($text.lg($text.medium))]));
  return $h.div(_pipe, attrs, children);
}

export function spreadsheet_wrapper(attrs, children) {
  let _pipe = $s.class$(
    toList([
      $s.display("flex"),
      $s.flex_direction("column"),
      $s.overflow_y("hidden"),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function spreadsheet(size, attrs, children) {
  let internal_size = size - 1;
  let _pipe = $s.class$(
    toList([
      $s.display("grid"),
      $s.grid_template_columns(
        ("min-content repeat(" + $int.to_string(internal_size)) + ", minmax(auto,max-content))",
      ),
      $s.border_right("1px solid " + $colors.light.grey_200),
      $s.overflow("auto"),
      $s.flex("1"),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function spreadsheet_sheets(attrs, children) {
  let _pipe = $s.class$(
    toList([
      $s.display("flex"),
      $s.gap(px(4)),
      $s.border_top("1px solid " + $colors.light.grey_200),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

function spreadsheet_sheet_cl() {
  return $s.class$(
    toList([
      $s.compose($text.xs($text.medium)),
      $s.border_bottom_left_radius(px(8)),
      $s.border_bottom_right_radius(px(8)),
      $s.border("0.5px solid " + $colors.light.grey_200),
      $s.border_top("none"),
      $s.padding_("4px 24px"),
      $s.cursor("pointer"),
      $s.hover(
        toList([
          $s.background($colors.light.grey_100),
          $s.color($colors.light.grey_800),
        ]),
      ),
    ]),
  );
}

export function spreadsheet_sheet(attrs, children) {
  let _pipe = $s.class$(
    toList([
      $s.compose(spreadsheet_sheet_cl()),
      $s.background($colors.light.white),
      $s.color($colors.light.grey_600),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function active_spreadsheet_sheet(attrs, children) {
  let _pipe = $s.class$(
    toList([
      $s.compose(spreadsheet_sheet_cl()),
      $s.background($colors.light.grey_50),
      $s.color($colors.light.onyx),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

function cell_base() {
  return $s.class$(
    toList([
      $s.compose($text.xs($text.regular)),
      $s.border("0.5px solid " + $colors.light.grey_200),
      $s.padding(px(8)),
    ]),
  );
}

export function empty_cell(attrs) {
  let _pipe = $s.class$(toList([$s.compose(cell_base())]));
  return $h.div(_pipe, attrs, toList([]));
}

export function filled_cell(attrs, children) {
  let _pipe = $s.class$(toList([$s.compose(cell_base())]));
  return $h.div(_pipe, attrs, children);
}

function shared_header_style() {
  return $s.class$(
    toList([
      $s.compose($text.xs($text.medium)),
      $s.padding_("8px 12px"),
      $s.background($colors.light.grey_50),
      $s.border("1px solid " + $colors.light.grey_200),
      $s.text_align("center"),
      $s.color($colors.light.onyx),
    ]),
  );
}

export function spreadsheet_header(attrs, children) {
  let _pipe = $s.class$(
    toList([
      $s.compose(shared_header_style()),
      $s.position("sticky"),
      $s.border("0.5px solid " + $colors.light.grey_200),
      $s.border_top("1px solid " + $colors.light.grey_200),
      $s.border_bottom("1px solid " + $colors.light.grey_200),
      $s.top(px(0)),
      $s.display("flex"),
      $s.flex_direction("column"),
      $s.gap(px(9)),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function spreadsheet_header_line(index, max, attrs, children) {
  let _pipe = $s.class$(
    toList([
      $s.compose(shared_header_style()),
      $s.border("0.5px solid " + $colors.light.grey_200),
      $s.border_left("1px solid " + $colors.light.grey_200),
      $s.position("sticky"),
      $s.left(px(0)),
      $s.display("flex"),
      $s.gap(px(9)),
      $s.align_items("center"),
      $s.z_index(max - index),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function dropdown_wrapper(attrs, children, opacity) {
  let _pipe = $s.class$(toList([$s.opacity(opacity)]));
  return $h.div(_pipe, attrs, children);
}

export function show_title(attrs, children) {
  let _pipe = $s.class$(toList([$s.compose($display.sm())]));
  return $h.div(_pipe, attrs, children);
}

export function show_title_form(attrs, children) {
  let _pipe = $s.class$(toList([$s.compose($display.sm())]));
  return $h.form(_pipe, attrs, children);
}

export function back_title(attrs, children) {
  let _pipe = $s.class$(toList([$s.compose($display.sm())]));
  return $h.div(_pipe, attrs, children);
}

export function empty_sheet(attrs, children) {
  let _pipe = $s.class$(
    toList([
      $s.color($colors.light.grey_600),
      $s.compose($text.xl($text.regular)),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function question_card(attrs, children) {
  let _pipe = $s.class$(
    toList([
      $s.display("grid"),
      $s.grid_template_columns("1fr auto"),
      $s.padding(px(24)),
      $s.gap(px(16)),
      $s.position("relative"),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function question_number(attrs, children) {
  let _pipe = $s.class$(
    toList([
      $s.compose($text.sm($text.medium)),
      $s.grid_column("1"),
      $s.grid_row("1"),
      $s.color($colors.light.grey_800),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function question_list(attrs, children) {
  let _pipe = $s.class$(
    toList([$s.display("flex"), $s.flex_direction("column")]),
  );
  return $h.div(_pipe, attrs, children);
}

export function page_selector(attrs, children) {
  let _pipe = $s.class$(
    toList([
      $s.width(percent(100)),
      $s.justify_content("center"),
      $s.align_items("center"),
      $s.display("flex"),
      $s.flex_direction("row"),
      $s.gap(px(6)),
      $s.padding(px(4)),
      $s.cursor("pointer"),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function question_questions(attrs, children) {
  let _pipe = $s.class$(
    toList([
      $s.flex("1"),
      $s.border("1px solid " + $colors.light.grey_400),
      $s.border_radius_("8px 8px 8px 0px"),
      $s.overflow("auto"),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function question_card_title(attrs, children) {
  let _pipe = $s.class$(
    toList([
      $s.display("grid"),
      $s.align_items("center"),
      $s.gap(px(12)),
      $s.grid_template_columns("auto auto 1fr auto"),
      $s.grid_column("1"),
      $s.z_index(1),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function question_spreadsheet_sheets(attrs, children) {
  let _pipe = $s.class$(toList([$s.display("flex"), $s.gap(px(4))]));
  return $h.div(_pipe, attrs, children);
}

export function question_status(attrs, children) {
  let _pipe = $s.class$(toList([$s.grid_row("1"), $s.grid_column("2")]));
  return $h.div(_pipe, attrs, children);
}

export function question_question(attrs, children) {
  let _pipe = $s.class$(
    toList([
      $s.grid_column("1 / 4"),
      $s.grid_row("2"),
      $s.compose($text.md($text.medium)),
      $s.color($colors.light.onyx),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function question_response(is_hint, attrs, children) {
  let _pipe = $s.class$(
    toList([
      $s.compose($text.md($text.regular)),
      $s.position("relative"),
      $s.color(
        (() => {
          if (!is_hint) {
            return $colors.light.paragraph;
          } else {
            return $colors.light.grey_600;
          }
        })(),
      ),
      $s.white_space("pre-wrap"),
      $s.border_radius(px(8)),
      $s.border("1px solid " + $colors.light.grey_400),
      $s.background($colors.light.white),
      $s.padding(px(16)),
      $s.transition("all .3s"),
      $s.focus(
        toList([
          $s.border("1px solid " + $colors.light.ai_400),
          $s.outline("none"),
          $s.box_shadow("0px 0px 0px 4px " + $colors.light.ai_100),
        ]),
      ),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function question_owner(attrs, children) {
  let _pipe = $s.class$(toList([$s.grid_column("4"), $s.grid_row("2")]));
  return $h.div(_pipe, attrs, children);
}

export function question_actions(attrs, children) {
  let _pipe = $s.class$(
    toList([
      $s.grid_column("2"),
      $s.grid_row("2"),
      $s.display("flex"),
      $s.align_items("center"),
      $s.position("relative"),
      $s.gap(px(16)),
      $s.align_self("center"),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function question_sources(attrs, children) {
  let _pipe = $s.class$(
    toList([
      $s.compose($text.xs($text.regular)),
      $s.grid_column("1"),
      $s.grid_row("3"),
      $s.display("flex"),
      $s.align_items("center"),
      $s.justify_content("space-between"),
      $s.gap(px(8)),
      $s.color($colors.light.grey_800),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function question_sources_title(attrs, children) {
  let _pipe = $s.class$(toList([$s.text_transform("uppercase")]));
  return $h.div(_pipe, attrs, children);
}

export function question_source_index(attrs, children) {
  let _pipe = $s.class$(
    toList([
      $s.hover(toList([$s.color($colors.light.onyx), $s.cursor("default")])),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function question_source_tooltip_title(attrs, children) {
  let _pipe = $s.class$(
    toList([$s.color($colors.light.onyx), $s.compose($text.sm($text.medium))]),
  );
  return $h.div(_pipe, attrs, children);
}

export function question_sources_infos(attrs, children) {
  return $h.div_(attrs, children);
}

export function confidence_score(attrs, children) {
  let _pipe = $s.class$(
    toList([
      $s.text_transform("uppercase"),
      $s.display("flex"),
      $s.align_items("center"),
      $s.gap(px(12)),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function confidence_score_highlight(value, attrs, children) {
  let _pipe = $s.class$(
    toList([
      $s.compose($text.xs($text.medium)),
      $s.color(
        (() => {
          if (value > 0.8) {
            return $colors.light.success_800;
          } else if (value > 0.35) {
            return $colors.light.orange_800;
          } else {
            return $colors.light.error_800;
          }
        })(),
      ),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function question_source_tooltip(attrs, children) {
  let _pipe = $s.class$(
    toList([
      $s.compose($text.xs($text.regular)),
      $s.margin_top(px(12)),
      $s.display("flex"),
      $s.flex_direction("column"),
      $s.padding(px(12)),
      $s.gap(px(12)),
      $s.border_radius(px(8)),
      $s.background($colors.light.grey_50),
      $s.box_shadow($shadows.m()),
      $s.color($colors.light.grey_800),
      $s.max_height(px(350)),
      $s.overflow("auto"),
      $s.white_space("pre-wrap"),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function details_collaborators_class() {
  return $s.class$(
    toList([
      $s.compose($text.sm($text.medium)),
      $s.display("flex"),
      $s.gap(px(8)),
      (() => {
        let _pipe = $s.padding(px(8));
        return $s.important(_pipe);
      })(),
      (() => {
        let _pipe = $s.background($colors.light.grey_100);
        return $s.important(_pipe);
      })(),
      (() => {
        let _pipe = $s.color($colors.light.paragraph);
        return $s.important(_pipe);
      })(),
      $s.border_radius(px(8)),
      $s.position("relative"),
      $s.align_items("center"),
      $s.hover(toList([$s.background($colors.light.grey_200)])),
      $s.disabled(
        toList([
          $s.background("transparent"),
          $s.cursor("default"),
          $s.padding(px(0)),
        ]),
      ),
    ]),
  );
}

export function details_collaborators_button(attrs, children) {
  let _pipe = $s.class$(toList([$s.compose(details_collaborators_class())]));
  return $h.button(_pipe, attrs, children);
}

export function details_collaborators_loading_button(attrs, children) {
  let _pipe = $s.class$(
    toList([
      $s.compose($text.sm($text.medium)),
      $s.display("flex"),
      $s.gap(px(8)),
      $s.padding(px(8)),
      $s.background($colors.light.grey_100),
      $s.color($colors.light.paragraph),
      $s.border_radius(px(8)),
      $s.position("relative"),
      $s.align_items("center"),
      $s.pointer_events("none"),
      $s.hover(toList([$s.background($colors.light.grey_200)])),
      $s.disabled(toList([$s.cursor("default"), $s.padding(px(0))])),
    ]),
  );
  return $h.button(_pipe, attrs, children);
}

export function details_collaborators_icon(child) {
  let _pipe = $s.class$(toList([$s.width(px(20)), $s.height(px(20))]));
  return $h.div(_pipe, toList([]), toList([child]));
}

export function large_icon(child) {
  let _pipe = $s.class$(toList([$s.width(px(24)), $s.height(px(24))]));
  return $h.div(_pipe, toList([]), toList([child]));
}

export function button_icon(dark, attrs, children) {
  let _pipe = $s.class$(
    toList([
      $s.position("relative"),
      $s.width(px(40)),
      $s.height(px(40)),
      $s.border_radius(px(20)),
      $s.background(
        (() => {
          if (!dark) {
            return $colors.light.grey_200;
          } else {
            return $colors.light.onyx;
          }
        })(),
      ),
      $s.color(
        (() => {
          if (!dark) {
            return $colors.light.grey_800;
          } else {
            return $colors.light.white;
          }
        })(),
      ),
      $s.display("flex"),
      $s.align_items("center"),
      $s.justify_content("center"),
      $s.transition("all .2s"),
      $s.hover(
        toList([
          $s.background($colors.light.onyx),
          $s.color($colors.light.white),
        ]),
      ),
      $s.disabled(
        toList([
          $s.background($colors.light.grey_50),
          $s.color($colors.light.grey_200),
          $s.cursor("default"),
          $s.pointer_events("none"),
        ]),
      ),
    ]),
  );
  return $h.button(_pipe, attrs, children);
}

export function details_collaborators_icon_delete(attrs, child) {
  let _pipe = $s.class$(
    toList([$s.width(px(16)), $s.height(px(16)), $s.cursor("pointer")]),
  );
  return $h.button(_pipe, attrs, toList([child]));
}

export function change_popup() {
  return $s.class$(
    toList([
      $s.position("absolute"),
      $s.top(px(45)),
      $s.border("1px solid " + $colors.light.grey_400),
      $s.border_radius(px(8)),
      $s.display("flex"),
      $s.flex_direction("column"),
      $s.background($colors.light.white),
      $s.cursor("default"),
      $s.overflow("auto"),
      $s.max_height(px(500)),
      $s.box_shadow($shadows.m()),
    ]),
  );
}

export function add_collaborators_popup_left(attrs, children) {
  let _pipe = $s.class$(
    toList([$s.compose(change_popup()), $s.width(px(250)), $s.left(px(0))]),
  );
  return $h.div(_pipe, attrs, children);
}

export function add_collaborators_popup_right(attrs, children) {
  let _pipe = $s.class$(
    toList([$s.compose(change_popup()), $s.width(px(250)), $s.right(px(0))]),
  );
  return $h.div(_pipe, attrs, children);
}

export function add_collaborators_popup_title(attrs, children) {
  let _pipe = $s.class$(
    toList([
      $s.compose($text.xs($text.medium)),
      $s.color($colors.light.grey_800),
      $s.padding(px(10)),
      $s.position("sticky"),
      $s.top(px(0)),
      $s.background($colors.light.white),
      $s.border_bottom("1px solid " + $colors.light.grey_400),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function add_collaborator_button(attrs, children) {
  let _pipe = $s.class$(
    toList([
      $s.display("flex"),
      $s.align_items("center"),
      $s.justify_content("space-between"),
      $s.gap(px(8)),
      $s.padding(px(10)),
      $s.cursor("pointer"),
      $s.hover(toList([$s.background($colors.light.grey_100)])),
    ]),
  );
  return $h.button(_pipe, attrs, children);
}

export function collaborators_list(attrs, children) {
  let _pipe = $s.class$(
    toList([
      $s.display("flex"),
      $s.align_items("center"),
      $s.gap(px(8)),
      $s.property("flex-wrap", "wrap"),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function details_owner_picture_deletion(attrs, children) {
  let _pipe = $s.class$(
    toList([
      $s.compose($text.sm($text.medium)),
      $s.display("flex"),
      $s.align_items("center"),
      $s.gap(px(6)),
      $s.padding(px(10)),
      $s.background($colors.light.grey_100),
      $s.border_radius(px(8)),
      $s.white_space("nowrap"),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function ai_rewording_popup(attrs, children) {
  let _pipe = $s.class$(
    toList([
      $s.compose($text.md($text.medium)),
      $s.position("absolute"),
      $s.display("flex"),
      $s.z_index(9000),
      $s.flex_direction("column"),
      $s.top(px(50)),
      $s.right(px(0)),
      $s.background($colors.light.white),
      $s.border_radius(px(8)),
      $s.box_shadow($shadows.m()),
      $s.border("1px solid " + $colors.light.grey_100),
      $s.overflow("hidden"),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function ai_rewording_title(attrs, children) {
  let _pipe = $s.class$(
    toList([
      $s.compose($text.sm($text.medium)),
      $s.color($colors.light.grey_600),
      $s.padding_("10px 14px"),
      $s.border_bottom("1px solid " + $colors.light.grey_200),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function ai_rewording_option(attrs, children) {
  let _pipe = $s.class$(
    toList([
      $s.compose($text.md($text.regular)),
      $s.white_space("nowrap"),
      $s.display("flex"),
      $s.align_items("center"),
      $s.padding_("10px 14px"),
      $s.gap(px(8)),
      $s.padding_right(px(30)),
      $s.color($colors.light.paragraph),
      $s.hover(toList([$s.background($colors.light.grey_100)])),
    ]),
  );
  return $h.button(_pipe, attrs, children);
}

export function ai_text_input(attrs, children) {
  let _pipe = $s.class$(
    toList([
      $s.display("flex"),
      $s.flex_direction("column"),
      $s.flex("1"),
      $s.overflow("hidden"),
      $s.padding_("8px 12px"),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function questions_wrapper(attrs, children) {
  let _pipe = $s.class$(
    toList([
      $s.display("flex"),
      $s.flex_direction("column"),
      $s.flex("1"),
      $s.overflow("hidden"),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

function typing_dot(size) {
  return $s.class$(
    toList([
      $s.background($colors.light.grey_800),
      $s.width(px(size)),
      $s.height(px(size)),
      $s.border_radius(px(20)),
      $s.animation("pulse 2s infinite"),
    ]),
  );
}

function typing_dot_1(size) {
  let _pipe = $s.class$(toList([$s.compose(typing_dot(size))]));
  return $h.div(_pipe, toList([]), toList([]));
}

function typing_dot_2(size) {
  let _pipe = $s.class$(
    toList([$s.compose(typing_dot(size)), $s.animation_delay("600ms")]),
  );
  return $h.div(_pipe, toList([]), toList([]));
}

function typing_dot_3(size) {
  let _pipe = $s.class$(
    toList([$s.compose(typing_dot(size)), $s.animation_delay("1200ms")]),
  );
  return $h.div(_pipe, toList([]), toList([]));
}

export function typing_indicator(size) {
  let _pipe = $s.class$(
    toList([$s.display("flex"), $s.gap(px(divideInt(size, 2)))]),
  );
  return $h.div(
    _pipe,
    toList([]),
    toList([typing_dot_1(size), typing_dot_2(size), typing_dot_3(size)]),
  );
}

export function loading_answer_indicator(children) {
  let _pipe = $s.class$(
    toList([
      $s.compose($text.md($text.medium)),
      $s.position("absolute"),
      $s.top(px(0)),
      $s.right(px(0)),
      $s.left(px(0)),
      $s.bottom(px(0)),
      $s.background("#fffb"),
      $s.border_radius(px(8)),
      $s.display("flex"),
      $s.align_items("center"),
      $s.justify_content("center"),
      $s.gap(px(16)),
      $s.property("backdrop-filter", "blur(6px)"),
    ]),
  );
  return $h.div(_pipe, toList([]), children);
}

export function trash_icon_button(attrs, children) {
  let _pipe = $s.class$(
    toList([
      $s.width(px(48)),
      $s.height(px(48)),
      $s.border_radius(px(24)),
      $s.color($colors.light.grey_800),
      $s.display("flex"),
      $s.align_items("center"),
      $s.justify_content("center"),
      $s.transition("all .3s"),
      $s.border("none"),
      $s.cursor("pointer"),
      $s.outline("none"),
      $s.hover(
        toList([
          $s.background($colors.light.grey_100),
          $s.color($colors.light.onyx),
        ]),
      ),
      $s.disabled(
        toList([
          $s.background($colors.light.grey_100),
          $s.color($colors.light.grey_400),
          $s.cursor("default"),
        ]),
      ),
    ]),
  );
  return $h.button(_pipe, attrs, children);
}

export function trash_icon_child() {
  let _pipe = $s.class$(toList([$s.width(px(16)), $s.height(px(16))]));
  return $h.div(_pipe, toList([]), toList([$outline.trash()]));
}

export function filters_button(active, attrs, children) {
  let _pipe = $s.class$(
    toList([
      $s.compose($text.sm($text.medium)),
      $s.compose(
        (() => {
          if (active) {
            return $button.button_primary();
          } else {
            return $button.button_secondary();
          }
        })(),
      ),
    ]),
  );
  return $h.button(_pipe, attrs, children);
}
