/// <reference types="./organization.d.mts" />
import * as $connector from "../../../common/data/connector.mjs";
import * as $connector_settings from "../../../common/data/connector_settings.mjs";
import * as $tag from "../../../common/data/tag.mjs";
import * as $user from "../../../common/data/user.mjs";
import * as $http from "../../../gleam_http/gleam/http.mjs";
import * as $json from "../../../gleam_json/gleam/json.mjs";
import * as $bool from "../../../gleam_stdlib/gleam/bool.mjs";
import * as $dynamic from "../../../gleam_stdlib/gleam/dynamic.mjs";
import * as $list from "../../../gleam_stdlib/gleam/list.mjs";
import * as $effect from "../../../lustre/lustre/effect.mjs";
import * as $model from "../../data/model.mjs";
import { Model } from "../../data/model.mjs";
import * as $msg from "../../data/msg.mjs";
import * as $ask from "../../frontend/ask.mjs";
import * as $middleware from "../../frontend/middleware.mjs";
import { toList } from "../../gleam.mjs";
import * as $utils from "../../utils.mjs";
import { to_path } from "../../utils.mjs";

export function update_user_function(model, user_id, function$) {
  return $middleware.require_access_token(
    model,
    (access_token) => {
      return $middleware.require_org_id(
        model,
        (org_id) => {
          return $effect.from(
            (_) => {
              let at = to_path(
                toList(["organizations", org_id, "members", user_id]),
              );
              let _pipe = $ask.to(new $ask.Heimdall(), at);
              let _pipe$1 = $ask.bearing(_pipe, access_token);
              let _pipe$2 = $ask.via(_pipe$1, new $http.Patch());
              let _pipe$3 = $ask.expect(_pipe$2, $dynamic.dynamic);
              let _pipe$4 = $ask.with$(
                _pipe$3,
                $json.object(
                  toList([["function", $user.encode_function(function$)]]),
                ),
              );
              $ask.run(_pipe$4)
              return undefined;
            },
          );
        },
      );
    },
  );
}

export function fetch_connectors_status(model) {
  return $middleware.require_access_token(
    model,
    (access_token) => {
      return $effect.from(
        (dispatch) => {
          return $list.each(
            toList([
              new $connector.GoogleDrive(),
              new $connector.Confluence(),
              new $connector.Notion(),
              new $connector.Slack(),
            ]),
            (connector) => {
              let on = (m) => {
                return dispatch(
                  new $msg.OnConnectors(
                    new $msg.ApiReturnedConnectorData(connector, m),
                  ),
                );
              };
              let _pipe = $ask.to(
                new $ask.Loki(),
                to_path(toList(["registered"])),
              );
              let _pipe$1 = $ask.bearing(_pipe, access_token);
              let _pipe$2 = $ask.query(
                _pipe$1,
                "connector",
                $connector.to_string(connector),
              );
              let _pipe$3 = $ask.expect(
                _pipe$2,
                $dynamic.field("registered", $dynamic.bool),
              );
              let _pipe$4 = $ask.notify(_pipe$3, on);
              return $ask.run(_pipe$4);
            },
          );
        },
      );
    },
  );
}

export function fetch_organization_members(model) {
  return $middleware.require_access_token(
    model,
    (access_token) => {
      return $middleware.require_org_id(
        model,
        (org_id) => {
          return $effect.from(
            (dispatch) => {
              let at = to_path(toList(["organizations", org_id, "members"]));
              let _pipe = $ask.to(new $ask.Heimdall(), at);
              let _pipe$1 = $ask.bearing(_pipe, access_token);
              let _pipe$2 = $ask.expect(
                _pipe$1,
                $dynamic.field("users", $dynamic.list($user.decode)),
              );
              let _pipe$3 = $ask.notify(
                _pipe$2,
                (users) => { return dispatch(new $msg.OnUsers(users)); },
              );
              $ask.run(_pipe$3)
              return undefined;
            },
          );
        },
      );
    },
  );
}

export function fetch_connectors_settings(model) {
  return $middleware.require_access_token(
    model,
    (access_token) => {
      return $middleware.require_org_id(
        model,
        (org_id) => {
          let is_admin = $list.contains(model.permissions, "admin:organization");
          return $bool.guard(
            !is_admin,
            $effect.none(),
            () => {
              return $effect.from(
                (dispatch) => {
                  let at = to_path(
                    toList(["organizations", org_id, "connectors-settings"]),
                  );
                  let _pipe = $ask.to(new $ask.Heimdall(), at);
                  let _pipe$1 = $ask.bearing(_pipe, access_token);
                  let _pipe$2 = $ask.expect(
                    _pipe$1,
                    $dynamic.list($connector_settings.decode),
                  );
                  let _pipe$3 = $ask.notify(
                    _pipe$2,
                    (d) => {
                      return dispatch(
                        new $msg.OnConnectors(
                          new $msg.ApiReturnedConnectorSettings(d),
                        ),
                      );
                    },
                  );
                  $ask.run(_pipe$3)
                  return undefined;
                },
              );
            },
          );
        },
      );
    },
  );
}

export function fetch_tags(model) {
  return $middleware.require_access_token(
    model,
    (access_token) => {
      return $effect.from(
        (dispatch) => {
          let _pipe = $ask.to(new $ask.Heimdall(), to_path(toList(["tags"])));
          let _pipe$1 = $ask.via(_pipe, new $http.Get());
          let _pipe$2 = $ask.bearing(_pipe$1, access_token);
          let _pipe$3 = $ask.expect(_pipe$2, $dynamic.list($tag.decode));
          let _pipe$4 = $ask.notify(
            _pipe$3,
            (t) => { return dispatch(new $msg.UpdateTags(t)); },
          );
          $ask.run(_pipe$4)
          return undefined;
        },
      );
    },
  );
}
