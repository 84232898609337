/// <reference types="./add.d.mts" />
import * as $birl from "../../../birl/birl.mjs";
import * as $user from "../../../common/data/user.mjs";
import * as $bool from "../../../gleam_stdlib/gleam/bool.mjs";
import * as $int from "../../../gleam_stdlib/gleam/int.mjs";
import * as $list from "../../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../../gleam_stdlib/gleam/option.mjs";
import * as $result from "../../../gleam_stdlib/gleam/result.mjs";
import * as $string from "../../../gleam_stdlib/gleam/string.mjs";
import * as $a from "../../../lustre/lustre/attribute.mjs";
import * as $el from "../../../lustre/lustre/element.mjs";
import * as $h from "../../../lustre/lustre/element/html.mjs";
import * as $e from "../../../lustre/lustre/event.mjs";
import * as $mime_types from "../../../mime_types/mime_types.mjs";
import * as $magic from "../../../sketch_magic/sketch/magic.mjs";
import * as $model from "../../data/model.mjs";
import * as $msg from "../../data/msg.mjs";
import * as $new_proposal from "../../data/ui/new_proposal.mjs";
import * as $button from "../../design_system/components/button.mjs";
import * as $dropdown from "../../design_system/components/dropdown.mjs";
import * as $input from "../../design_system/components/input.mjs";
import * as $layout from "../../design_system/components/layout.mjs";
import * as $pp from "../../design_system/components/profile_picture.mjs";
import * as $progress_circles from "../../design_system/components/progress/circles.mjs";
import * as $icons from "../../design_system/icons.mjs";
import * as $illustrations from "../../design_system/illustrations.mjs";
import * as $display from "../../design_system/typography/display.mjs";
import * as $text from "../../design_system/typography/text.mjs";
import { Ok, Error, toList, prepend as listPrepend, makeError } from "../../gleam.mjs";
import * as $utils from "../../utils.mjs";
import { select_columns } from "../../view/components/spreadsheet_selector.mjs";
import * as $s from "../../view/styles/proposals.mjs";

function center(children) {
  return $layout.column(
    toList([
      $layout.align("center"),
      $layout.justify("center"),
      $layout.flex("1"),
      $layout.gap(12),
    ]),
    toList([]),
    children,
  );
}

function loading() {
  return center(
    toList([
      $layout.row(
        toList([$layout.align("baseline")]),
        toList([]),
        toList([
          $h.div(
            toList([$a.class$($magic.class_name($display.md()))]),
            toList([$h.text("Loading")]),
          ),
          $s.typing_indicator(4),
        ]),
      ),
      $h.div(
        toList([$a.class$($magic.class_name($text.md($text.regular)))]),
        toList([$h.text("Please wait")]),
      ),
    ]),
  );
}

function update_date(content) {
  let parts = (() => {
    let _pipe = content;
    let _pipe$1 = $string.split(_pipe, "-");
    let _pipe$2 = $list.map(_pipe$1, $int.parse);
    return $result.all(_pipe$2);
  })();
  if (parts.isOk() && parts[0].hasLength(3)) {
    let year = parts[0].head;
    let month = parts[0].tail.head;
    let day = parts[0].tail.tail.head;
    let _pipe = new $birl.Day(year, month, day);
    let _pipe$1 = ((_capture) => {
      return $birl.set_day($birl.utc_now(), _capture);
    })(_pipe);
    return new $msg.UserUpdatedDate(_pipe$1);
  } else {
    return new $msg.OnNewProposalSkip();
  }
}

function on_remove_collaborator(user_id) {
  return $e.on(
    "click",
    (event) => {
      $e.prevent_default(event);
      $e.stop_propagation(event);
      return new Ok(new $msg.UserRemovedCollaborator(user_id));
    },
  );
}

function add_collaborators(proposal, users, owner_id) {
  let _pipe = toList([
    $layout.gap(6),
    $layout.align("start"),
    $layout.position("relative"),
  ]);
  return $layout.column(
    _pipe,
    toList([]),
    toList([
      $s.details_owner_label(toList([]), toList([$h.text("Collaborators")])),
      $dropdown.dropdown(
        toList([
          $dropdown.placeholder("Add collaborators"),
          $dropdown.icon($icons.add_user()),
          $dropdown.input_class($s.details_collaborators_class()),
          $dropdown.on_selected(
            (var0) => { return new $msg.UserAddedCollaborator(var0); },
          ),
        ]),
        listPrepend(
          $dropdown.section("Select collaborators"),
          $list.filter_map(
            users,
            (user) => {
              let is_owner = user.id === owner_id;
              let is_contributor = $list.any(
                user.roles,
                (r) => { return r.name === "Contributor"; },
              );
              let is_not_selectable = !is_contributor || is_owner;
              return $bool.guard(
                is_not_selectable,
                new Error(undefined),
                () => {
                  let id = user.id;
                  let name = user.name;
                  let picture = user.picture;
                  let initials = user.initials;
                  let icon = $pp.from_user(picture, initials, new $pp.Medium());
                  return new Ok(
                    (() => {
                      let _pipe$1 = $dropdown.choice(name, id);
                      return $dropdown.with_icon(_pipe$1, icon);
                    })(),
                  );
                },
              );
            },
          ),
        ),
      ),
      $s.collaborators_list(
        toList([]),
        $list.map(
          proposal.collaborators,
          (user_id) => {
            let user = $list.find(users, (u) => { return u.id === user_id; });
            let picture = $result.map(user, (u) => { return u.picture; });
            let initials = $result.map(user, (u) => { return u.initials; });
            let name = $result.map(user, (u) => { return u.name; });
            return $s.details_owner_picture_deletion(
              toList([]),
              toList([
                $pp.from_user(
                  $result.unwrap(picture, new $option.None()),
                  $result.unwrap(initials, "?"),
                  new $pp.Small(),
                ),
                $h.text($result.unwrap(name, "Unknown name")),
                $s.details_collaborators_icon_delete(
                  toList([on_remove_collaborator(user_id)]),
                  $icons.cross(),
                ),
              ]),
            );
          },
        ),
      ),
    ]),
  );
}

function enter_proposal_details(proposal, users, owner_id) {
  let $ = $birl.get_day(proposal.due_date);
  let year = $.year;
  let month = $.month;
  let day = $.date;
  let datetime_value = (() => {
    let _pipe = toList([year, month, day]);
    let _pipe$1 = $list.map(_pipe, $int.to_string);
    let _pipe$2 = $list.map(
      _pipe$1,
      (_capture) => { return $string.pad_left(_capture, 2, "0"); },
    );
    return $string.join(_pipe$2, "-");
  })();
  let user = $list.find(users, (u) => { return u.id === owner_id; });
  let name = (() => {
    let _pipe = $result.map(user, (u) => { return u.name; });
    return $result.unwrap(_pipe, "Unknown Name");
  })();
  return $s.details_wrapper(
    toList([$e.on_submit(new $msg.UserSubmittedDetails())]),
    toList([
      $illustrations.documents_stack(),
      $layout.column(
        toList([$layout.gap(64), $layout.flex("1"), $layout.overflow("auto")]),
        toList([]),
        toList([
          $layout.column(
            toList([$layout.gap(16)]),
            toList([]),
            toList([
              $s.details_title(
                toList([]),
                toList([$h.text("Enter proposal details")]),
              ),
              $input.input(
                toList([
                  $input.label("Name"),
                  $input.value(proposal.name),
                  $input.on_input(
                    (content) => { return new $msg.UserUpdatedName(content); },
                  ),
                ]),
              ),
              $input.input(
                toList([
                  $input.label("Due Date"),
                  $input.type_("date"),
                  $input.value(datetime_value),
                  $input.on_input(update_date),
                ]),
              ),
              $input.input(
                toList([
                  $input.label("Client"),
                  $input.value(proposal.client),
                  $input.on_input(
                    (content) => { return new $msg.UserUpdatedClient(content); },
                  ),
                ]),
              ),
              $layout.column(
                toList([$layout.gap(6)]),
                toList([]),
                toList([
                  $s.details_owner_label(toList([]), toList([$h.text("Owner")])),
                  $s.details_owner_picture(
                    toList([]),
                    toList([
                      $pp.from_user(
                        (() => {
                          let _pipe = $result.map(
                            user,
                            (u) => { return u.picture; },
                          );
                          return $result.unwrap(_pipe, new $option.None());
                        })(),
                        (() => {
                          let _pipe = $result.map(
                            user,
                            (u) => { return u.initials; },
                          );
                          return $result.unwrap(_pipe, "?");
                        })(),
                        new $pp.Medium(),
                      ),
                      $h.text(name),
                    ]),
                  ),
                ]),
              ),
              add_collaborators(proposal, users, owner_id),
            ]),
          ),
          $layout.row(
            toList([$layout.justify("end")]),
            toList([]),
            toList([
              $button.primary(
                toList([$a.type_("submit")]),
                toList([$h.text("Confirm")]),
              ),
            ]),
          ),
        ]),
      ),
    ]),
  );
}

export function qualification_matrix() {
  let use_qualification_matrix = "Would you like to use a proposal qualification matrix?";
  let save_time = "This copy prompts that by using this feature the user can save a lot of time in the process.";
  return $s.layout_wrapper(
    toList([]),
    toList([
      $illustrations.lightbulb_flash(),
      $layout.column(
        toList([$layout.gap(16)]),
        toList([]),
        toList([
          $layout.column(
            toList([$layout.gap(8)]),
            toList([]),
            toList([
              $s.details_title(
                toList([]),
                toList([$h.text(use_qualification_matrix)]),
              ),
              $s.details_subtitle(toList([]), toList([$h.text(save_time)])),
            ]),
          ),
          $layout.row(
            toList([$layout.gap(16)]),
            toList([]),
            toList([
              $button.primary(
                toList([$e.on_click(new $msg.UserUsedQualificationMatrix())]),
                toList([$h.text("Yes")]),
              ),
              $button.secondary(
                toList([$e.on_click(new $msg.UserSkippedQualificationMatrix())]),
                toList([
                  $h.text("Maybe next time"),
                  $button.icon($icons.right_arrow()),
                ]),
              ),
            ]),
          ),
        ]),
      ),
    ]),
  );
}

function upload_file() {
  return $s.layout_wrapper(
    toList([]),
    toList([
      $s.drag_and_drop_zone(
        toList([]),
        toList([
          $s.icon_wrapper(toList([]), toList([$icons.upload()])),
          $layout.column(
            toList([$layout.gap(16), $layout.align("center")]),
            toList([]),
            toList([
              $s.drop_title(
                toList([]),
                toList([$h.text("Select your file to upload")]),
              ),
              $s.drop_subtitle(
                toList([]),
                toList([$h.text("We support excel questionnaires today.")]),
              ),
            ]),
          ),
          $h.label(
            toList([]),
            toList([
              $s.file_input(toList([]), toList([$h.text("Select files")])),
              $h.input(
                toList([
                  $a.type_("file"),
                  $a.style(toList([["display", "none"]])),
                  $a.attribute(
                    "accept",
                    (() => {
                      let _pipe = toList([
                        ".xls",
                        ".xlsx",
                        $mime_types.to_string(new $mime_types.Xlsx()),
                      ]);
                      return $string.join(_pipe, ",");
                    })(),
                  ),
                  $utils.on_files_change(
                    (files) => {
                      let $ = $list.first(files);
                      if (!$.isOk()) {
                        throw makeError(
                          "let_assert",
                          "view/proposals/add",
                          271,
                          "",
                          "Pattern match failed, no pattern matched the value.",
                          { value: $ }
                        )
                      }
                      let file = $[0];
                      let _pipe = file;
                      let _pipe$1 = new $msg.UserSelectedXlsx(_pipe);
                      return new $msg.OnNewProposalSpreadsheetSelector(_pipe$1);
                    },
                  ),
                ]),
              ),
            ]),
          ),
        ]),
      ),
    ]),
  );
}

export function view(model, is_loading) {
  let new_proposal = model.new_proposal;
  let $ = model.user;
  if (!($ instanceof $option.Some)) {
    throw makeError(
      "let_assert",
      "view/proposals/add",
      34,
      "view",
      "Pattern match failed, no pattern matched the value.",
      { value: $ }
    )
  }
  let user = $[0];
  return $el.fragment(
    toList([
      $progress_circles.steps(
        toList([
          (() => {
            let _pipe = $progress_circles.step(new_proposal.step);
            return $progress_circles.description(
              _pipe,
              "Enter proposal details",
            );
          })(),
          (() => {
            let _pipe = $progress_circles.step(new_proposal.step);
            return $progress_circles.description(_pipe, "Upload file");
          })(),
          (() => {
            let _pipe = $progress_circles.step(new_proposal.step);
            return $progress_circles.description(_pipe, "Select columns");
          })(),
        ]),
      ),
      $bool.guard(
        is_loading,
        loading(),
        () => {
          let $1 = new_proposal.step;
          if ($1 === 1) {
            return enter_proposal_details(new_proposal, model.users, user.sub);
          } else if ($1 === 2) {
            return upload_file();
          } else if ($1 === 3) {
            let _pipe = select_columns(
              new_proposal.proposal_spreadsheet,
              "Generate your responses",
              false,
            );
            return $el.map(
              _pipe,
              (var0) => {
                return new $msg.OnNewProposalSpreadsheetSelector(var0);
              },
            );
          } else {
            return $el.none();
          }
        },
      ),
    ]),
  );
}
