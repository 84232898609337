/// <reference types="./effects.d.mts" />
import * as $effect from "../../lustre/lustre/effect.mjs";
import * as $model from "../data/model.mjs";
import { Model } from "../data/model.mjs";
import * as $msg from "../data/msg.mjs";
import * as $content_library_effects from "../frontend/effects/content_library.mjs";
import * as $copilot_effects from "../frontend/effects/copilot.mjs";
import * as $organization_effects from "../frontend/effects/organization.mjs";
import * as $proposal_effects from "../frontend/effects/proposal.mjs";
import * as $user_effects from "../frontend/effects/user.mjs";
import * as $ffi from "../frontend/ffi.mjs";
import { toList } from "../gleam.mjs";

export function fetch_user_data(model) {
  return $effect.batch(
    toList([
      $proposal_effects.fetch_proposals(model),
      $copilot_effects.fetch_copilot_questions(model),
      $organization_effects.fetch_connectors_status(model),
      $content_library_effects.fetch_content_library(model),
      $content_library_effects.fetch_status_stats(model),
      $organization_effects.fetch_organization_members(model),
      $organization_effects.fetch_connectors_settings(model),
      $user_effects.fetch_notifications(model),
      $organization_effects.fetch_tags(model),
    ]),
  );
}

export function toast(msg, level) {
  return $effect.from(
    (dispatch) => {
      let _pipe = new $msg.DisplayToast(level, msg);
      return dispatch(_pipe);
    },
  );
}

export function blur_active_element() {
  return $effect.from((_) => { return $ffi.blur_active_element(); });
}

export function subscribe_dom_click(on_unsubscriber, on_click) {
  return $effect.from(
    (dispatch) => {
      let unsubscriber = $ffi.subscribe_dom_click(
        () => { return dispatch(on_click); },
      );
      return dispatch(on_unsubscriber(unsubscriber));
    },
  );
}

export function on_connected_user(model) {
  return $effect.batch(
    toList([
      $user_effects.register_slack_user(model),
      $user_effects.connect_websocket(model),
      fetch_user_data(model),
    ]),
  );
}
