/// <reference types="./user.d.mts" />
import * as $app_config from "../../../app_config/app_config.mjs";
import * as $notification from "../../../common/data/notification.mjs";
import * as $http from "../../../gleam_http/gleam/http.mjs";
import * as $dynamic from "../../../gleam_stdlib/gleam/dynamic.mjs";
import * as $uri from "../../../gleam_stdlib/gleam/uri.mjs";
import * as $effect from "../../../lustre/lustre/effect.mjs";
import * as $model from "../../data/model.mjs";
import { Model } from "../../data/model.mjs";
import * as $msg from "../../data/msg.mjs";
import * as $ask from "../../frontend/ask.mjs";
import * as $middleware from "../../frontend/middleware.mjs";
import * as $websocket from "../../frontend/websocket.mjs";
import { toList, makeError } from "../../gleam.mjs";
import * as $utils from "../../utils.mjs";
import { to_path } from "../../utils.mjs";

export function fetch_notifications(model) {
  return $middleware.require_access_token(
    model,
    (access_token) => {
      return $effect.from(
        (dispatch) => {
          let at = to_path(toList(["notifications"]));
          let _pipe = $ask.to(new $ask.Heimdall(), at);
          let _pipe$1 = $ask.bearing(_pipe, access_token);
          let _pipe$2 = $ask.expect(
            _pipe$1,
            $dynamic.list($notification.decode),
          );
          let _pipe$3 = $ask.notify(
            _pipe$2,
            (n) => { return dispatch(new $msg.UpdateNotifications(n)); },
          );
          $ask.run(_pipe$3)
          return undefined;
        },
      );
    },
  );
}

export function mark_notification_as_read(model, notification_id) {
  return $middleware.require_access_token(
    model,
    (access_token) => {
      return $effect.from(
        (dispatch) => {
          let at = to_path(toList(["notifications", notification_id]));
          let _pipe = $ask.to(new $ask.Heimdall(), at);
          let _pipe$1 = $ask.via(_pipe, new $http.Patch());
          let _pipe$2 = $ask.bearing(_pipe$1, access_token);
          let _pipe$3 = $ask.expect(_pipe$2, $notification.decode);
          let _pipe$4 = $ask.notify(
            _pipe$3,
            (n) => { return dispatch(new $msg.UpdateNotification(n)); },
          );
          $ask.run(_pipe$4)
          return undefined;
        },
      );
    },
  );
}

export function mark_all_notifications_as_read(model) {
  return $middleware.require_access_token(
    model,
    (access_token) => {
      return $effect.from(
        (_) => {
          let at = to_path(toList(["notifications"]));
          let _pipe = $ask.to(new $ask.Heimdall(), at);
          let _pipe$1 = $ask.via(_pipe, new $http.Patch());
          let _pipe$2 = $ask.bearing(_pipe$1, access_token);
          $ask.run(_pipe$2)
          return undefined;
        },
      );
    },
  );
}

export function register_slack_user(model) {
  return $middleware.require_access_token(
    model,
    (access_token) => {
      return $middleware.require_slack_login(
        model,
        (team_id, user_id) => {
          return $effect.from(
            (_) => {
              let at = to_path(toList(["slack", "login"]));
              let _pipe = $ask.to(new $ask.Loki(), at);
              let _pipe$1 = $ask.via(_pipe, new $http.Post());
              let _pipe$2 = $ask.bearing(_pipe$1, access_token);
              let _pipe$3 = $ask.query(_pipe$2, "slack_team_id", team_id);
              let _pipe$4 = $ask.query(_pipe$3, "slack_user_id", user_id);
              $ask.run(_pipe$4)
              return undefined;
            },
          );
        },
      );
    },
  );
}

export function connect_websocket(model) {
  return $middleware.require_access_token(
    model,
    (access_token) => {
      let $ = $uri.parse($app_config.wss_endpoint());
      if (!$.isOk()) {
        throw makeError(
          "let_assert",
          "frontend/effects/user",
          66,
          "",
          "Pattern match failed, no pattern matched the value.",
          { value: $ }
        )
      }
      let to = $[0];
      return $websocket.connect(to, access_token);
    },
  );
}
