/// <reference types="./proposal.d.mts" />
import * as $app_config from "../../../app_config/app_config.mjs";
import * as $proposal from "../../../common/data/proposal.mjs";
import * as $sentry from "../../../common/sentry.mjs";
import * as $fetch from "../../../gleam_fetch/gleam/fetch.mjs";
import * as $http from "../../../gleam_http/gleam/http.mjs";
import * as $request from "../../../gleam_http/gleam/http/request.mjs";
import * as $promise from "../../../gleam_javascript/gleam/javascript/promise.mjs";
import * as $json from "../../../gleam_json/gleam/json.mjs";
import * as $dynamic from "../../../gleam_stdlib/gleam/dynamic.mjs";
import * as $list from "../../../gleam_stdlib/gleam/list.mjs";
import * as $result from "../../../gleam_stdlib/gleam/result.mjs";
import * as $level from "../../../grille_pain/grille_pain/toast/level.mjs";
import * as $effect from "../../../lustre/lustre/effect.mjs";
import * as $global from "../../../plinth/plinth/javascript/global.mjs";
import * as $model from "../../data/model.mjs";
import { Model } from "../../data/model.mjs";
import * as $msg from "../../data/msg.mjs";
import * as $ask from "../../frontend/ask.mjs";
import * as $error from "../../frontend/error.mjs";
import * as $ffi from "../../frontend/ffi.mjs";
import * as $middleware from "../../frontend/middleware.mjs";
import { Ok, toList, makeError } from "../../gleam.mjs";
import * as $utils from "../../utils.mjs";
import { to_path } from "../../utils.mjs";

export function delete_proposal(model, proposal_id) {
  return $middleware.require_access_token(
    model,
    (access_token) => {
      return $effect.from(
        (_) => {
          let at = to_path(toList(["proposals", proposal_id]));
          let _pipe = $ask.to(new $ask.Heimdall(), at);
          let _pipe$1 = $ask.via(_pipe, new $http.Delete());
          let _pipe$2 = $ask.bearing(_pipe$1, access_token);
          $ask.run(_pipe$2)
          return undefined;
        },
      );
    },
  );
}

export function delete_question_in_proposal(model, proposal_id, question_id) {
  return $middleware.require_access_token(
    model,
    (access_token) => {
      return $effect.from(
        (_) => {
          let at = to_path(
            toList(["proposals", proposal_id, "questions", question_id]),
          );
          let _pipe = $ask.to(new $ask.Heimdall(), at);
          let _pipe$1 = $ask.via(_pipe, new $http.Delete());
          let _pipe$2 = $ask.bearing(_pipe$1, access_token);
          $ask.run(_pipe$2)
          return undefined;
        },
      );
    },
  );
}

export function download_xlsx(model, proposal, toast_id) {
  return $middleware.require_access_token(
    model,
    (access_token) => {
      return $effect.from(
        (dispatch) => {
          let url = $app_config.heimdall_endpoint();
          let url$1 = ((url + "/proposals/") + proposal.id) + "/export";
          let $ = $request.to(url$1);
          if (!$.isOk()) {
            throw makeError(
              "let_assert",
              "frontend/effects/proposal",
              58,
              "",
              "Pattern match failed, no pattern matched the value.",
              { value: $ }
            )
          }
          let request = $[0];
          let authorization = "Bearer " + access_token;
          let _pipe = request;
          let _pipe$1 = $request.set_header(
            _pipe,
            "authorization",
            authorization,
          );
          let _pipe$2 = $request.set_method(_pipe$1, new $http.Get());
          let _pipe$3 = $fetch.send(_pipe$2);
          let _pipe$4 = $promise.try_await(_pipe$3, $fetch.read_bytes_body);
          let _pipe$5 = $promise.map(
            _pipe$4,
            (_capture) => {
              return $result.map_error(
                _capture,
                (var0) => { return new $error.FetchError(var0); },
              );
            },
          );
          let _pipe$6 = $promise.tap(
            _pipe$5,
            (dyn) => {
              if (dyn.isOk()) {
                let response = dyn[0];
                return $ffi.dl_file(proposal.name, response.body);
              } else {
                return $promise.resolve(undefined);
              }
            },
          );
          let _pipe$7 = $promise.map(
            _pipe$6,
            (_) => {
              dispatch(new $msg.HideToast(toast_id));
              let msg = "File downloaded successfully!";
              $global.set_timeout(
                1000,
                () => {
                  return dispatch(
                    new $msg.DisplayToast(new $level.Success(), msg),
                  );
                },
              );
              return new Ok(undefined);
            },
          );
          $promise.rescue(
            _pipe$7,
            (dyn) => {
              $sentry.capture(dyn);
              dispatch(new $msg.HideToast(toast_id));
              let msg = "Impossible to download the file. Please, retry later.";
              $global.set_timeout(
                1000,
                () => {
                  return dispatch(
                    new $msg.DisplayToast(new $level.Error(), msg),
                  );
                },
              );
              return new Ok(undefined);
            },
          )
          return undefined;
        },
      );
    },
  );
}

export function update_proposal_name(model, id) {
  let proposal = $list.key_find(model.proposals, id);
  return $middleware.require_access_token(
    model,
    (access_token) => {
      if (!proposal.isOk()) {
        return $effect.none();
      } else {
        let proposal$1 = proposal[0];
        return $effect.from(
          (dispatch) => {
            let at = to_path(toList(["proposals", id]));
            let _pipe = $ask.to(new $ask.Heimdall(), at);
            let _pipe$1 = $ask.bearing(_pipe, access_token);
            let _pipe$2 = $ask.via(_pipe$1, new $http.Patch());
            let _pipe$3 = $ask.with$(
              _pipe$2,
              $json.object(toList([["proposal", $proposal.encode(proposal$1)]])),
            );
            let _pipe$4 = $ask.expect(
              _pipe$3,
              $dynamic.field("proposal", $proposal.decode),
            );
            let _pipe$5 = $ask.notify(
              _pipe$4,
              (q) => {
                return dispatch(new $msg.ApiReturnedProposals(toList([q])));
              },
            );
            $ask.run(_pipe$5)
            return undefined;
          },
        );
      }
    },
  );
}

export function fetch_proposals(model) {
  return $middleware.require_access_token(
    model,
    (access_token) => {
      return $effect.from(
        (dispatch) => {
          let _pipe = $ask.to(
            new $ask.Heimdall(),
            to_path(toList(["proposals"])),
          );
          let _pipe$1 = $ask.bearing(_pipe, access_token);
          let _pipe$2 = $ask.expect(_pipe$1, $dynamic.list($proposal.decode));
          let _pipe$3 = $ask.notify(
            _pipe$2,
            (p) => { return dispatch(new $msg.ApiReturnedProposals(p)); },
          );
          $ask.run(_pipe$3)
          return undefined;
        },
      );
    },
  );
}

export function toggle_proposal_collaborator(model, proposal_id, user_id) {
  let proposal = $list.key_find(model.proposals, proposal_id);
  return $middleware.require_access_token(
    model,
    (access_token) => {
      if (!proposal.isOk()) {
        return $effect.none();
      } else {
        let proposal$1 = proposal[0];
        return $effect.from(
          (dispatch) => {
            let new$ = $proposal.toggle_collaborator(proposal$1, user_id);
            let _pipe = $ask.to(
              new $ask.Heimdall(),
              to_path(toList(["proposals", proposal_id])),
            );
            let _pipe$1 = $ask.bearing(_pipe, access_token);
            let _pipe$2 = $ask.via(_pipe$1, new $http.Patch());
            let _pipe$3 = $ask.with$(
              _pipe$2,
              $json.object(toList([["proposal", $proposal.encode(new$)]])),
            );
            let _pipe$4 = $ask.expect(
              _pipe$3,
              $dynamic.field("proposal", $proposal.decode),
            );
            let _pipe$5 = $ask.notify(
              _pipe$4,
              (p) => {
                return dispatch(new $msg.ApiReturnedProposals(toList([p])));
              },
            );
            let _pipe$6 = $ask.error(
              _pipe$5,
              (_) => {
                return dispatch(new $msg.ApiRejectedProposal(proposal$1));
              },
            );
            $ask.run(_pipe$6)
            return undefined;
          },
        );
      }
    },
  );
}
