/// <reference types="./new_proposal.d.mts" />
import * as $proposal from "../../../common/data/proposal.mjs";
import * as $question from "../../../common/data/question.mjs";
import * as $http from "../../../gleam_http/gleam/http.mjs";
import * as $dynamic from "../../../gleam_stdlib/gleam/dynamic.mjs";
import * as $pair from "../../../gleam_stdlib/gleam/pair.mjs";
import * as $effect from "../../../lustre/lustre/effect.mjs";
import * as $model from "../../data/model.mjs";
import { Model } from "../../data/model.mjs";
import * as $msg from "../../data/msg.mjs";
import * as $new_proposal from "../../data/ui/new_proposal.mjs";
import * as $ask from "../../frontend/ask.mjs";
import * as $middleware from "../../frontend/middleware.mjs";
import { Ok } from "../../gleam.mjs";

export function send_new_proposal(model) {
  return $middleware.require_access_token(
    model,
    (access_token) => {
      return $middleware.require_org_id(
        model,
        (org_id) => {
          return $middleware.require_user_id(
            model,
            (user_id) => {
              return $effect.from(
                (dispatch) => {
                  let new_proposal = model.new_proposal;
                  let $ = $new_proposal.encode(new_proposal, org_id, user_id);
                  if (!$.isOk()) {
                    return undefined;
                  } else {
                    let form_data = $[0];
                    let at = "/proposals";
                    let _pipe = $ask.to(new $ask.Heimdall(), at);
                    let _pipe$1 = $ask.bearing(_pipe, access_token);
                    let _pipe$2 = $ask.via(_pipe$1, new $http.Post());
                    let _pipe$3 = $ask.data(_pipe$2, form_data);
                    let _pipe$4 = $ask.expect(
                      _pipe$3,
                      $dynamic.decode2(
                        $pair.new$,
                        $dynamic.field("proposal", $proposal.decode),
                        $dynamic.field(
                          "questions",
                          $dynamic.list($question.decode),
                        ),
                      ),
                    );
                    let _pipe$5 = $ask.notify(
                      _pipe$4,
                      (r) => {
                        return dispatch(
                          (() => {
                            let _pipe$5 = new $msg.ApiReturnedSavedProposal(
                              new Ok(r),
                            );
                            return new $msg.OnNewProposal(_pipe$5);
                          })(),
                        );
                      },
                    );
                    $ask.run(_pipe$5)
                    return undefined;
                  }
                },
              );
            },
          );
        },
      );
    },
  );
}
