/// <reference types="./bar.d.mts" />
import * as $int from "../../../../gleam_stdlib/gleam/int.mjs";
import * as $s from "../../../../sketch/sketch.mjs";
import * as $size from "../../../../sketch/sketch/size.mjs";
import { percent, px } from "../../../../sketch/sketch/size.mjs";
import * as $h from "../../../../sketch_magic/sketch/magic/element/html.mjs";
import * as $colors from "../../../design_system/colors.mjs";
import * as $text from "../../../design_system/typography/text.mjs";
import { toList } from "../../../gleam.mjs";

function progress_wrapper(attrs, children) {
  let _pipe = $s.class$(
    toList([
      $s.compose($text.md($text.medium)),
      $s.display("flex"),
      $s.gap(px(24)),
      $s.align_items("center"),
      $s.color($colors.light.success_800),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

function progress_inside(completion) {
  let _pipe = $s.class$(
    toList([
      $s.width(percent(completion)),
      $s.background($colors.light.success_800),
      $s.border_radius(px(8)),
      $s.transition("width .3s"),
    ]),
  );
  return $h.div(_pipe, toList([]), toList([]));
}

function progress_bar(completion) {
  let _pipe = $s.class$(
    toList([
      $s.display("flex"),
      $s.height(px(8)),
      $s.background($colors.light.grey_200),
      $s.border_radius(px(8)),
      $s.overflow("hidden"),
      $s.flex("1"),
    ]),
  );
  return $h.div(_pipe, toList([]), toList([progress_inside(completion)]));
}

export function progress(completion) {
  return progress_wrapper(
    toList([]),
    toList([
      $h.text($int.to_string(completion) + " %"),
      progress_bar(completion),
    ]),
  );
}
